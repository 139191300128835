import PropTypes from 'prop-types';
import FetchPublicApiComponent from '../../../../../common/atoms/FetchPublicApiComponent';

export default class BaseModals extends FetchPublicApiComponent {
  constructor (props) {
    super(props);
    this.state = {
      // 途中経過で使うステート
      areaCode: null,
      prefectureCode: null,
      prefectureName: null,
      cityCode: null,
      stationAreaCode: null,
      stationPrefectureCode: null,
      lineCode: null,
      lineName: null,
      diseaseAlphabeticalRow: null,
      diseaseAlphabeticalOrder: null,
      departmentDivision: null,
      departmentCodes: [],
      diseaseOtherSearchMode: false,
      // fetchで非同期更新するステート
      lines: [],
      stations: [],
      cities: [],
      diseases: []
    };
    this.fetchLines = this.fetchLines.bind(this);
    this.clearLines = this.clearLines.bind(this);
    this.fetchStations = this.fetchStations.bind(this);
    this.clearStations = this.clearStations.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
    this.clearCities = this.clearCities.bind(this);
    this.fetchDiseases = this.fetchDiseases.bind(this);
    this.clearDiseases = this.clearDiseases.bind(this);
  }

  /* 都道府県別の路線検索 */
  fetchLines (prefectureCode = null) {
    const apiLocation = `${this.props.mixerHost}/station_lines?prefecture_code=${prefectureCode}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ lines: data.data });
      });
  }

  clearLines () {
    this.setState({ lines: [] });
  }

  /* 都道府県×路線別の路線検索 */
  fetchStations (prefectureCode = null, lineCode = null) {
    const apiLocation = `${this.props.mixerHost}/station_lines?prefecture_code=${prefectureCode}&line_code=${lineCode}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ stations: data.data[0].station_masters });
      });
  }

  clearStations () {
    this.setState({ stations: [] });
  }

  /* 都道府県×路線別の路線検索 */
  fetchCities (prefectureCode = null) {
    const apiLocation = `${this.props.mixerHost}/cities?prefecture_code=${prefectureCode}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ cities: data.data });
      });
  }

  clearCities () {
    this.setState({ cities: [] });
  }

  /* 病名の候補リスト検索 */
  fetchDiseases (searchTagName = null) {
    let apiLocation = `${this.props.mixerHost}/tags?name_condition=prefix&kind[]=1&page=1&end_user_visible=true&limit=1000&name[]=${searchTagName}`;
    if (searchTagName.toString() === 'その他') {
      apiLocation = `${this.props.mixerHost}/tags?kind[]=1&page=1&limit=1000&end_user_visible=true&not_start_with_kana=true`;
    }

    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ diseases: data.data });
      });
  }

  clearDiseases () {
    this.setState({ diseases: [] });
  }

  /* エリア選択モーダル用 */
  setAreaCode (areaCode) {
    this.setState({ areaCode: areaCode });
  }

  setPrefectureCode (prefectureCode) {
    this.setState({ prefectureCode: prefectureCode });
  }

  setPrefectureName (prefectureName) {
    this.setState({ prefectureName: prefectureName });
  }

  setCityCode (cityCode) {
    this.setState({ cityCode: cityCode });
  }

  /* 駅/路線選択モーダル用 */
  setStationAreaCode (stationAreaCode) {
    this.setState({ stationAreaCode: stationAreaCode });
  }

  setStationPrefectureCode (stationPrefectureCode) {
    this.setState({ stationPrefectureCode: stationPrefectureCode });
  }

  setLineCode (lineCode) {
    this.setState({ lineCode: lineCode });
  }

  setLineName (lineName) {
    this.setState({ lineName: lineName });
  }

  /* 病名から探すモーダル用 */
  setDiseaseAlphabeticalRow (diseaseAlphabeticalRow) {
    this.setState({ diseaseAlphabeticalRow: diseaseAlphabeticalRow });
  }

  setDiseaseAlphabeticalOrder (diseaseAlphabeticalOrder) {
    this.setState({ diseaseAlphabeticalOrder: diseaseAlphabeticalOrder });
  }

  setDiseaseOtherSearchMode (diseaseOtherSearchMode) {
    this.setState({ diseaseOtherSearchMode: diseaseOtherSearchMode });
  }

  /* 診療科選択モーダル用 */
  setDepartmentDivision (departmentDivision) {
    this.setState({ departmentDivision: departmentDivision });
  }

  pushDepartmentCode (departmentCode) {
    this.setState(state => {
      state.departmentCodes.push(departmentCode);
      return { departmentCodes: state.departmentCodes };
    });
  }
}

BaseModals.propTypes = {
  modal: PropTypes.any,
  setModal: PropTypes.func,
  setDepartmentDivision: PropTypes.func,
  setDepartmentCode: PropTypes.func,
  setAreaCode: PropTypes.func,
  setPrefectureCode: PropTypes.func,
  setCityCode: PropTypes.func,
  areaCode: PropTypes.string,
  setStationAreaCode: PropTypes.func,
  setStationPrefectureCode: PropTypes.func,
  setLineCode: PropTypes.func,
  setLineName: PropTypes.func,
  stationAreaCode: PropTypes.string,
  stationPrefectureCode: PropTypes.string,
  departmentDivision: PropTypes.string,
  departmentCode: PropTypes.string
};
