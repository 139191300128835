import React from 'react';
import IconSearch from '../../atoms/IconSearch';
import SearchCondition from '../../../../common/atoms/hospitals/SearchCondition';
import {
  AbstractComponent
} from '../../../../common/atoms/index';

export default class TopDiseaseForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      value: '',
      suggest: {},
      showSuggest: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (event) {
    const word = event.target.value;
    this.setState({ value: word });
    this.fetchSuggest(word);
  }

  handleSelect (suggest) {
    const searchCondition = new SearchCondition({ treatments: [suggest] });
    location.href = searchCondition.url();
  }

  fetchSuggest (word) {
    if (word === '') {
      this.setState({ suggests: [], showSuggest: false });
      return;
    }

    const apiLocation = `${this.props.mixerHost}/suggest/treatments?word=${word}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState({ suggest: data.data, showSuggest: Object.values(data.data).some(data => data.length > 0) });
      });
  }

  renderSuggest () {
    const suggests = {
      treatments: [
        { key: 'diseases', label: '病名' }
      ]
    };

    return (
      <div className="p-institution-search__search-form-fixed-type-suggest js-suggest--fixed" style={{ display: 'block' }}>
        {suggests.treatments.map(suggest => {
          const treatments = this.state.suggest[suggest.key];
          if (!treatments || treatments.length === 0) {
            return null;
          }

          return (
            <div key={suggest.key} className="p-institution-search__search-form-fixed-type-suggest-inner">
              <p className="p-institution-search__search-form-fixed-type-suggest-inner-title">{suggest.label}</p>
              <ul className="c-mn-tag-list">
                {treatments.map(treatment => {
                  return (
                    <li key={`${treatment.name}${treatment.code}`}>
                      <a
                        className="c-mn-tag-link"
                        href=""
                        onClick={event => {
                          event.preventDefault();
                          this.handleSelect(treatment);
                        }}
                      >
                        {treatment.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  render () {
    return (
      <div className="p-institution-search__input">
        <label className="p-institution-search__input-label">病名</label>
        <input
          value={this.state.value}
          type="text"
          className="c-mn-input-text"
          placeholder="病名を入力"
          onChange={this.handleChange}
        />
        {this.state.showSuggest ? this.renderSuggest() : null}
        <button
          className="p-institution-search__input-btn"
          onClick={event => event.preventDefault()}
        >
          <IconSearch />
        </button>
      </div>
    );
  }
}
