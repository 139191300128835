import React from 'react';
import SearchCondition from '../../../common/atoms/hospitals/SearchCondition';
import HospitalSearchMessage from '../../../common/atoms/HospitalSearchMessage';

export default class CommonSearch extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // 表示するモーダルの制御ステート
      modal: null,
      // 一つ前に表示していたモーダルの保持ステート
      previousModal: null,
      // エリア駅の検索条件
      // 例) { code: "01407", name: "余市郡仁木町(北海道)", type: "city" }
      searchAreaCondition: null,
      // 病名/診療科/症状/治療名の検索条件
      // 例) [{ code: "20", name: "内科", type: "department" },{ code: "20", name: "内科", type: "department" }]
      searchTreatmentCondition: [],
      // 検索条件オプション
      searchOptionConditions: [],
      // 病院名・医師検索
      institutionNameOrDoctorNameCondition: null
    };
    this.maxTreatmentsLength = 5;
    // 引数のない関数はバインド
    this.displaySearchTreatmentConditions = this.displaySearchTreatmentConditions.bind(this);
    this.clearSearchCondition = this.clearSearchCondition.bind(this);
    this.searchUrl = this.searchUrl.bind(this);
  }

  componentDidMount () {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
  }

  // アクティブにするモーダルをセット
  setModal (modal) {
    this.setState({ modal: modal });
    const body = document.body;
    if (modal) {
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      body.classList.add('fixed');
      body.style.top = `-${scrollY}`;
    } else {
      const scrollY = body.style.top;
      body.classList.remove('fixed');
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }

  // 一つ前にアクティブだったモーダルをセット
  setPreviousModal (modal) {
    this.setState({ previousModal: modal });
  }

  // エリア/駅 検索条件設定
  setSearchAreaCondition (searchAreaObject) {
    this.setState({ searchAreaCondition: searchAreaObject });
  }

  // 病名/診療科/症状/治療名 検索条件設定
  // 検索条件が設定されている場合、重複チェックをおこない重複していなければ検索条件にpushする
  setSearchTreatmentCondition (searchTreatmentObject) {
    if (this.state.searchTreatmentCondition.length >= this.maxTreatmentsLength) {
      alert(HospitalSearchMessage.TreatmentsMaxCountAlert);
      return;
    }

    if (this.state.searchTreatmentCondition.length > 0 && this.state.searchTreatmentCondition.some(setCondition =>
      setCondition.type === searchTreatmentObject.type &&
      setCondition.code === searchTreatmentObject.code)) {
      return;
    }

    this.setState(state => {
      state.searchTreatmentCondition.push(searchTreatmentObject);
      return { searchTreatmentCondition: state.searchTreatmentCondition };
    });
  }

  // 病名/診療科/症状/治療名 検索条件設定(初期表示用)
  setSearchTreatmentConditions (searchTreatmentObjects) {
    this.setState({ searchTreatmentCondition: searchTreatmentObjects });
  }

  // 病名/診療科/症状/治療名 検索条件削除
  deleteSearchTreatmentCondition (searchTreatmentObject) {
    this.setState(state => {
      // 削除対象のインデックスを保持
      const index = state.searchTreatmentCondition.indexOf(searchTreatmentObject);
      // ステートから削除対象のサジェストオブジェクトを破壊的に削除
      state.searchTreatmentCondition.splice(index, 1);

      return { searchTreatmentCondition: state.searchTreatmentCondition };
    });
  }

  // 病名/診療科/症状/治療名 検索条件の表示文字列生成
  displaySearchTreatmentConditions () {
    const labels = this.state.searchTreatmentCondition.map(searchTreatmentCondition => {
      return searchTreatmentCondition.name;
    });
    return labels.join(',');
  }

  // その他の検索条件設定
  // 検索条件が設定されている場合、重複チェックをおこない重複していなければ検索条件にpushする
  setSearchOptionCondition (searchOptionConditionObject) {
    if (this.state.searchOptionConditions.length > 0 && this.state.searchOptionConditions.some(setCondition =>
      setCondition.type === searchOptionConditionObject.type &&
      setCondition.code === searchOptionConditionObject.code)) {
      return;
    }

    this.setState(state => {
      state.searchOptionConditions.push(searchOptionConditionObject);
      return { searchOptionConditions: state.searchOptionConditions };
    });
  }

  // 病院名・医師名検索
  setInstitutionNameOrDoctorNameCondition (institutionNameOrDoctorName) {
    this.setState({ institutionNameOrDoctorNameCondition: institutionNameOrDoctorName });
  }

  // その他の検索条件設定
  // 引数に渡したtype名のその他の検索条件のインデックス値を返す
  findIndexSearchOptionCondition (searchOptionConditionName) {
    return this.state.searchOptionConditions.findIndex(({ type }) => type === searchOptionConditionName);
  }

  // その他の検索条件設定
  // 引数に渡したtype名のその他の検索条件が設定されているか判定
  findSearchOptionCondition (searchOptionConditionName) {
    return this.findIndexSearchOptionCondition(searchOptionConditionName) !== -1;
  }

  // その他の検索条件設定 文字列引数で受けたtypeを持つ検索条件を削除
  deleteSearchOptionCondition (searchOptionConditionName) {
    // 削除対象のインデックスを保持
    const index = this.findIndexSearchOptionCondition(searchOptionConditionName);
    if (index !== -1) {
      // ステートから削除対象のサジェストオブジェクトを破壊的に削除
      // -1は対象が見つからなかった場合に返り意図せぬ削除が起こる。
      this.setState(state => {
        state.searchOptionConditions.splice(index, 1);

        return { searchOptionConditions: state.searchOptionConditions };
      });
    }
  }

  // 全検索条件 削除
  clearSearchCondition () {
    this.setState(state => {
      state.searchAreaCondition = null;
      state.searchTreatmentCondition = [];
      return { searchAreaCondition: state.searchAreaCondition, searchTreatmentCondition: state.searchTreatmentCondition };
    });
  }

  // 検索URLを取得
  searchUrl () {
    const searchCondition = new SearchCondition({
      treatments: this.state.searchTreatmentCondition,
      area: this.state.searchAreaCondition,
      options: this.state.searchOptionConditions,
      institutionNameOrDoctorName: this.state.institutionNameOrDoctorNameCondition
    });
    return searchCondition.url();
  }

  // 検索URLを取得(key, value指定)
  searchSelectConditionUrl (conditionKey, conditionValue) {
    const searchCondition = new SearchCondition({ [conditionKey]: conditionValue });
    return searchCondition.url();
  }
}
