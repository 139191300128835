import React from 'react';
import RecentHospitalComponent from '../../../../common/organisms/RecentHospitalComponent';

export default class RecentHospitalIndex extends RecentHospitalComponent {
  componentDidMount () {
    this.fetchRecentHospitals();
  }

  render () {
    return (
      <>
        { this.state.recentHospitals.length
          ? <>
            { this.state.recentHospitals.length
              ? (
                <div className="pg-institution-search-top__recent-search">
                  <div className="pg-institution-search-top__recent-search-inner">
                    <div className="pg-institution-search-top__recent-search-inner-title">
                      <svg className="c-mn-icon-time" width={15} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.366 0C4.84598 0 0.375977 4.48 0.375977 10C0.375977 15.52 4.84598 20 10.366 20C15.896 20 20.376 15.52 20.376 10C20.376 4.48 15.896 0 10.366 0ZM10.376 18C5.95598 18 2.37598 14.42 2.37598 10C2.37598 5.58 5.95598 2 10.376 2C14.796 2 18.376 5.58 18.376 10C18.376 14.42 14.796 18 10.376 18Z" fill="#000" />
                        <path className="c-mn-icon-time-path" d="M10.876 5H9.37598V11L14.626 14.15L15.376 12.92L10.876 10.25V5Z" fill="#000" />
                      </svg>
                      最近見た病院
                    </div>
                    <ul className="pg-institution-search-top__recent-search-inner-card-list">
                      { this.state.recentHospitals.map(hospital => {
                        return (
                          <li className="pg-institution-search-top__recent-search-inner-card-list-item" key={hospital.code}>
                            <a className="pg-institution-search-top__recent-search-inner-card-link" href={`/hospitals/${hospital.code}`}>
                              <p className="pg-institution-search-top__recent-search-inner-card-name">{ hospital.name }</p>
                              <p className="pg-institution-search-top__recent-search-inner-card-address">{ hospital.prefecture }</p>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <hr className="c-mn-line-gray-second" />
                </div>
              )
              : null }
          </>
          : null }
      </>
    );
  }
}
