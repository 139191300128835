import React from 'react';
import RecentHospitalComponent from '../../../../common/organisms/RecentHospitalComponent';

export default class RecentHospitalIndex extends RecentHospitalComponent {
  componentDidMount () {
    this.fetchRecentHospitals();
  }

  render () {
    return (
      <>
        { this.state.recentHospitals.length
          ? (
            <div className="pg-institution-search-top__recent-search">
              <div className="pg-institution-search-top__recent-search-title">
                <svg className="c-mn-icon-time" width={15} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.366 0C4.84598 0 0.375977 4.48 0.375977 10C0.375977 15.52 4.84598 20 10.366 20C15.896 20 20.376 15.52 20.376 10C20.376 4.48 15.896 0 10.366 0ZM10.376 18C5.95598 18 2.37598 14.42 2.37598 10C2.37598 5.58 5.95598 2 10.376 2C14.796 2 18.376 5.58 18.376 10C18.376 14.42 14.796 18 10.376 18Z" fill="#000" />
                  <path className="c-mn-icon-time-path" d="M10.876 5H9.37598V11L14.626 14.15L15.376 12.92L10.876 10.25V5Z" fill="#000" />
                </svg>最近見た病院
              </div>
              <ul className="pg-institution-search-top__recent-search-card-list">
                { this.state.recentHospitals.map(hospital => {
                  return (
                    <li className="pg-institution-search-top__recent-search-card-list-item" key={hospital.code}>
                      <a className="pg-institution-search-top__recent-search-card-link" href={`/hospitals/${hospital.code}`}>
                        <div className="pg-institution-search-top__recent-search-card-name">
                          <p>{ hospital.name }</p>
                        </div>
                        <p className="pg-institution-search-top__recent-search-card-address">
                          <svg className="c-mn-icon-pin" width="15" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="c-mn-icon-pin-path" fillRule="evenodd" clipRule="evenodd" d="M7.15592 0C3.65789 0 0.489258 2.832 0.489258 6.336C0.489258 9.84 3.38024 14.008 7.15592 20C10.9316 14.008 13.8226 9.832 13.8226 6.336C13.8226 2.84 10.6547 0 7.15592 0ZM7.15672 9.16798C5.80971 9.14072 4.73663 8.00994 4.75378 6.63583C4.77094 5.26172 5.87189 4.15918 7.21915 4.16691C8.5664 4.17464 9.65512 5.28976 9.65711 6.66398C9.65131 7.33431 9.38457 7.97481 8.9156 8.44445C8.44663 8.91409 7.8139 9.17437 7.15672 9.16798Z" fill="#000"></path>
                          </svg>{ hospital.prefecture } { hospital.station }
                        </p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
          : null }
      </>
    );
  }
}
