import React from 'react';
import PropTypes from 'prop-types';
import CommonSearch from '../../atoms/CommonSearch';
import SearchModals from '../../organisms/hospitals/modal/SearchModals';

export default class SearchForm extends CommonSearch {
  // その他の検索条件(チェックボックス)の操作
  handleChangeOptionCondition (event, optionConditionName) {
    const checked = event.target.checked;
    if (checked) {
      this.setSearchOptionCondition({ type: optionConditionName, code: checked });
    } else {
      this.deleteSearchOptionCondition(optionConditionName);
    }
  }

  handleClickSearch () {
    location.href = this.searchUrl();
  }

  render () {
    return (
      <>
        <div className="pg-institution-search-top__search-area">
          <div className="p-institution-search__input">
            {/* <label className="p-institution-search__input-label">病名/診療科/症状/治療名</label> */}
            <label className="p-institution-search__input-label">診療科/症状/治療名</label>
            {/* @NOTE: onChangeは実装上必要ないがreactのwarningが出るため何もしない空関数を指定している */}
            {/*
            <input
              className="c-mn-input-text"
              type="text"
              placeholder="病名/診療科等を入力"
              onClick={() => this.setModal('treatment')}
              value={this.state.searchTreatmentCondition ? this.displaySearchTreatmentConditions() : '' }
              onChange={() => {}}
            />
            */}
            <input
              className="c-mn-input-text"
              type="text"
              placeholder="診療科等を入力"
              onClick={() => this.setModal('treatment')}
              value={this.state.searchTreatmentCondition ? this.displaySearchTreatmentConditions() : '' }
              onChange={() => {}}
            />
            <button
              className="p-institution-search__input-btn"
              onClick={() => this.handleClickSearch()}
            >
              <svg className="c-mn-icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.95 21.14" width="15"><path className="c-mn-icon-search-path" d="M20.08,21.54a1.26,1.26,0,0,1-.89-.37l-3.93-3.93-.27.19a9.21,9.21,0,0,1-5.26,1.63,9.33,9.33,0,1,1,9.33-9.33,9.25,9.25,0,0,1-1.78,5.48l-.2.27,3.9,3.9a1.27,1.27,0,0,1-.9,2.16ZM9.73,2.45A7.28,7.28,0,1,0,17,9.73,7.29,7.29,0,0,0,9.73,2.45Z" transform="translate(-0.4 -0.4)"></path></svg>
            </button>
          </div>
          <ul className="c-mn-tag-list">
            {this.props.senbeiTags.map((item, index) => {
              return (
                <li key={index}><a className="c-mn-tag-link" href={item.url}>{item.name}</a></li>
              );
            })}
          </ul>
          <div className="c-mn-checkbox-btn c-mn-checkbox-btn--no-border">
            <input
              className="c-mn-checkbox-btn__checkbox"
              id="checkbox06" name="checkbox_cul2"
              type="checkbox"
              onChange={event => this.handleChangeOptionCondition(event, 'is_free_mail_consultation_available')}
              checked={this.findSearchOptionCondition('is_free_mail_consultation_available')}
            />
            <label className="c-mn-checkbox-btn__label" htmlFor="checkbox06">
              <svg className="c-mn-icon-check" width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="c-mn-icon-check-path" d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000"></path><path className="c-mn-icon-check-path" d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000"></path></svg>
              医師に無料メール相談ができる病院
            </label>
          </div>
          <p className="pg-institution-search-top__search-area-detail">
            <a className="pg-institution-search-top__search-area-detail-link js-modal-btn-detail" onClick={() => this.setModal('detail')}>詳細条件を追加する
              <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
            </a>
          </p>
          <hr className="c-mn-line-second" />
        </div>
        {/* 各モーダルのDOM */}
        <SearchModals
          modal={this.state.modal}
          previousModal={'detail'}
          setModal={(modalName) => this.setModal(modalName)}
          setPreviousModal={(modalName) => this.setPreviousModal(modalName)}
          setSearchAreaCondition={(searchAreaObject) => this.setSearchAreaCondition(searchAreaObject)}
          searchAreaCondition={this.state.searchAreaCondition}
          setTreatmentCondition={(searchTreatmentObject) => this.setSearchTreatmentCondition(searchTreatmentObject)}
          searchTreatmentCondition={this.state.searchTreatmentCondition}
          setSearchTreatmentCondition={(searchTreatmentObject) => this.setSearchTreatmentCondition(searchTreatmentObject)}
          findSearchOptionCondition={(searchOptionConditionName) => this.findSearchOptionCondition(searchOptionConditionName)}
          setSearchOptionCondition={(isFreeMailConsultationCondition) => this.setSearchOptionCondition(isFreeMailConsultationCondition)}
          deleteSearchOptionCondition={(searchOptionConditionType) => this.deleteSearchOptionCondition(searchOptionConditionType)}
          setSearchIsFreeMailConsultation={(isFreeMailConsultationCondition) => this.setSearchIsFreeMailConsultation(isFreeMailConsultationCondition)}
          institutionNameOrDoctorNameCondition={this.state.institutionNameOrDoctorNameCondition}
          setInstitutionNameOrDoctorNameCondition={(institutionNameOrDoctorName) => this.setInstitutionNameOrDoctorNameCondition(institutionNameOrDoctorName)}
          deleteSearchTreatmentCondition={(searchTreatmentObject) => this.deleteSearchTreatmentCondition(searchTreatmentObject)}
          displaySearchTreatmentConditions={this.displaySearchTreatmentConditions}
          clearSearchCondition={this.clearSearchCondition}
          mixerHost={this.props.mixerHost}
          searchUrl={this.searchUrl}
        />
      </>
    );
  }
}

SearchForm.propTypes = {
  mixerHost: PropTypes.string.isRequired,
  senbeiTags: PropTypes.array.isRequired
};

SearchForm.defaultProps = {
  mixerHost: '',
  senbeiTags: []
};
