// [駅/路線] 駅選択モーダル
import React from 'react';
import PropTypes from 'prop-types';

export default class StationSelectModal extends React.Component {
  prevModal () {
    this.props.setModal('lineSelect');
    this.props.clearStations();
  }

  handleClick (station) {
    // エリア/駅の検索条件をセットする
    // { type: 'station', code: '駅コード', name: '駅名(路線名)' }
    const condition = { type: 'station', code: station.station_code, name: `${station.station_name}駅(${this.props.lineName})` };
    this.props.setSearchAreaCondition(condition);

    if (this.props.previousModal === 'detail') {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else if (this.props.previousModal === 'areaSearchForm') {
      // 治療支援検索TOP下部のエリア/駅から探すから遷移してきた場合は選択した駅で検索結果画面へ遷移する。
      location.href = this.props.searchSelectConditionUrl('area', condition);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">駅/路線</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {this.props.stations.map((station) => {
            return (
              <li className="c-mn-sel-list" key={station.id}>
                <a onClick={() => this.handleClick(station)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{station.station_name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

StationSelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  previousModal: PropTypes.string,
  setSearchAreaCondition: PropTypes.func,
  searchSelectConditionUrl: PropTypes.func,
  clearStations: PropTypes.func,
  stations: PropTypes.array,
  lineName: PropTypes.string
};

StationSelectModal.defaultProps = {
  stations: []
};
