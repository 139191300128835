import React from 'react';
import PropTypes from 'prop-types';
import BaseModals from './BaseModals';
import AreaModal from './area/AreaModal';
import AreaSelectModal from './area/AreaSelectModal';
import PrefectureSelectModal from './area/PrefectureSelectModal';
import CitySelectModal from './area/CitySelectModal';
import StationAreaSelectModal from './station/AreaSelectModal';
import StationPrefectureSelectModal from './station/PrefectureSelectModal';
import LineSelectModal from './station/LineSelectModal';
import StationSelectModal from './station/StationSelectModal';

export default class SearchTopAreaModals extends BaseModals {
  render () {
    return (
      <div className="l-modal">
        <div className={this.props.modal ? 'p-institution-search__modal is-active' : 'p-institution-search__modal'}>
          {/* エリア/駅 選択モーダル */}
          <AreaModal
            isActive={this.props.modal === 'area'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            mixerHost={this.props.mixerHost}
            searchAreaCondition={this.props.searchAreaCondition}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
            searchUrl={this.props.searchUrl}
          />
          {/* [エリア] エリア選択モーダル */}
          <AreaSelectModal
            isActive={this.props.modal === 'areaSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setAreaCode={(areaCode) => this.setAreaCode(areaCode)}
          />
          {/* [エリア] 都道府県選択モーダル */}
          <PrefectureSelectModal
            isActive={this.props.modal === 'prefectureSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            setPrefectureCode={(prefectureCode) => this.setPrefectureCode(prefectureCode)}
            setPrefectureName={(prefectureName) => this.setPrefectureName(prefectureName)}
            areaCode={this.state.areaCode}
            fetchCities={this.fetchCities}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
            searchSelectConditionUrl={(key, value) => this.props.searchSelectConditionUrl(key, value)}
          />
          {/* [エリア] 市区町村選択モーダル */}
          <CitySelectModal
            isActive={this.props.modal === 'citySelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            prefectureCode={this.state.prefectureCode}
            prefectureName={this.state.prefectureName}
            cities={this.state.cities}
            clearCities={this.clearCities}
            setCityCode={(cityCode) => this.setCityCode(cityCode)}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
            searchSelectConditionUrl={(key, value) => this.props.searchSelectConditionUrl(key, value)}
          />
          {/* [駅/路線] エリア選択モーダル */}
          <StationAreaSelectModal
            isActive={this.props.modal === 'stationAreaSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setStationAreaCode={(stationAreaCode) => this.setStationAreaCode(stationAreaCode)}
          />
          {/* [駅/路線] 都道府県選択モーダル */}
          <StationPrefectureSelectModal
            isActive={this.props.modal === 'stationPrefectureSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            stationAreaCode={this.state.stationAreaCode}
            stationPrefectureCode={this.state.stationPrefectureCode}
            setStationPrefectureCode={(stationPrefectureCode) => this.setStationPrefectureCode(stationPrefectureCode)}
            fetchLines={this.fetchLines}
          />
          {/* [駅/路線] 路線選択モーダル */}
          <LineSelectModal
            isActive={this.props.modal === 'lineSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            stationAreaCode={this.state.stationAreaCode}
            stationPrefectureCode={this.state.stationPrefectureCode}
            setLineCode={(lineCode) => this.setLineCode(lineCode)}
            setLineName={(lineName) => this.setLineName(lineName)}
            lines={this.state.lines}
            clearLines={this.clearLines}
            fetchStations={this.fetchStations}
            mixerHost={this.props.MixerHost}
          />
          {/* [駅/路線] 駅選択モーダル */}
          <StationSelectModal
            isActive={this.props.modal === 'stationSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            stationPrefectureCode={this.state.stationPrefectureCode}
            lineCode={this.state.lineCode}
            lineName={this.state.lineName}
            stations={this.state.stations}
            clearStations={this.clearStations}
            mixerHost={this.props.MixerHost}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
            searchSelectConditionUrl={(key, value) => this.props.searchSelectConditionUrl(key, value)}
          />
        </div>
      </div>
    );
  }
}

SearchTopAreaModals.propTypes = {
  modal: PropTypes.any,
  setModal: PropTypes.func,
  setAreaCode: PropTypes.func,
  setPrefectureCode: PropTypes.func,
  setCityCode: PropTypes.func,
  areaCode: PropTypes.string,
  setStationAreaCode: PropTypes.func,
  setStationPrefectureCode: PropTypes.func,
  setLineCode: PropTypes.func,
  setLineName: PropTypes.func,
  stationAreaCode: PropTypes.string,
  stationPrefectureCode: PropTypes.string
};
