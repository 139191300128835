export default class JapaneseAlphabeticalRows {
  static japaneseAlphabeticalRows = [
    { code: '01', name: 'あ行' },
    { code: '02', name: 'か行' },
    { code: '03', name: 'さ行' },
    { code: '04', name: 'た行' },
    { code: '05', name: 'な行' },
    { code: '06', name: 'は行' },
    { code: '07', name: 'ま行' },
    { code: '08', name: 'や行' },
    { code: '09', name: 'ら行' },
    { code: '10', name: 'わ行' },
    { code: '99', name: 'その他' }
  ];

  static all () {
    return this.japaneseAlphabeticalRows;
  }
}
