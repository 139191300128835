// 診療科選択モーダル
import * as React from 'react';
import PropTypes from 'prop-types';
import Departments from '../../../../../../common/atoms/Departments';

export default class DepartmentSelectModal extends React.Component {
  handleClick (departmentObject) {
    if (this.props.previousModal) {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルから治療系モーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
    // 検索条件をセットする
    // { type: 'department', code: '診療科コード値', name: '診療科名 }
    this.props.setSearchTreatmentCondition(departmentObject);
  }

  prevModal () {
    this.props.setModal('departmentDivisionSelect');
  }

  render () {
    const departments = this.props.departmentDivision ? Departments.selectedDivisionTags(this.props.departmentDivision) : [];

    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">診療科</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {departments.map((department) => {
            return (
              <li className="c-mn-sel-list" key={department.code}>
                <a onClick={() => this.handleClick(department)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{department.name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

DepartmentSelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  previousModal: PropTypes.string,
  departmentDivision: PropTypes.string,
  setSearchTreatmentCondition: PropTypes.func
};
