// エリアモーダル
import * as React from 'react';
import PropTypes from 'prop-types';
import FetchPublicApiComponent from '../../../../../../common/atoms/FetchPublicApiComponent';
import AreaModalSuggestList from '../../../../atoms/AreaModalSuggestList';
import AreaModalMenuList from '../../../../atoms/AreaModalMenuList';
import AreaModalSelectedSuggestList from '../../../../atoms/AreaModalSelectedSuggestList';

export default class AreaModal extends FetchPublicApiComponent {
  constructor (props) {
    super(props);
    this.props.setModal(null);
    this.getCurrentArea = this.getCurrentArea.bind(this);
    this.state = {
      // サジェスト入力欄のvalue値
      word: '',
      // サジェスト候補リスト
      suggests: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickNextButton = this.handleClickNextButton.bind(this);
    this.areaRef = React.createRef();
  }

  componentDidUpdate () {
    this.areaRef.current.focus();
  }

  closeModal () {
    if (this.props.previousModal === 'detail') {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  // サジェストAPI
  fetchSuggestAreas (word = '') {
    if (word.length < 1) {
      return this.setState({ suggests: [] });
    }
    const apiLocation = `${this.props.mixerHost}/suggest/areas?word=${word}`;

    this
      .fetch(apiLocation)
      .then(data => {
        const suggests = [];
        // 市区町村の表示名加工
        data.data.prefectures.forEach(prefecture => {
          suggests.push({ type: 'prefecture', code: prefecture.prefecture_code, name: prefecture.prefecture_name });
        });
        // 市区町村の表示名加工
        data.data.cities.forEach(city => {
          suggests.push({ type: 'city', code: city.city_code, name: `${city.city_name}(${city.prefecture_name})` });
        });
        // 駅の表示名加工
        data.data.stations.forEach(station => {
          suggests.push({ type: 'station', code: station.station_code, name: `${station.station_name}駅(${station.line_name})` });
        });
        // サジェストリストデータの更新
        this.setState({ suggests: suggests });
      });
  }

  // サジェスト検索
  handleChange (event) {
    const word = event.target.value;
    this.setState({ word: word });
    this.fetchSuggestAreas(word);
  }

  // サジェストリストを選択時の処理
  handleSelected (suggest) {
    this.setState(state => {
      // 選択済みの検索条件を保持
      this.props.setSearchAreaCondition(suggest);
      // サジェストリスト、検索ワードの初期化
      return { suggests: [], word: '' };
    });
  }

  // 次へボタンを押下時の処理
  handleClickNextButton () {
    if (this.props.previousModal === 'detail') {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else if (this.props.previousModal === 'areaSearchForm') {
      // 治療支援検索TOP下部のエリア/駅から探すから遷移してきた場合は選択した駅で検索結果画面へ遷移する。
      location.href = this.props.searchUrl();
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  // サジェストから選択した検索条件の削除
  handleDeleteSelected () {
    this.props.setSearchAreaCondition(null);
  }

  getCurrentArea () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setCurrentArea, this.errorCurrentArea);
    } else {
      // 現在位置を取得できない場合の処理
      this.errorCurrentArea();
    }
  }

  setCurrentArea = (position) => {
    const data = position.coords;
    const lat = data.latitude;
    const lng = data.longitude;
    this.setState(state => {
      // 選択済みの検索条件を保持
      this.props.setSearchAreaCondition({ type: 'area', code: 'current_area', name: '現在地周辺', latitude: lat, longitude: lng });
      // サジェストリスト、検索ワードの初期化
      return { suggests: [], word: '' };
    }, this.props.setModal(this.props.previousModal));
  }

  errorCurrentArea () {
    alert('現在位置を取得できません');
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.closeModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">エリア/駅</p>
          <hr className="c-mn-line-second" />
        </div>
        {/* サジェスト検索要素 */}
        <div className="p-institution-search__modal-search-wrap">
          <div className="p-institution-search__modal-search">
            { this.props.searchAreaCondition !== null
              ? <AreaModalSelectedSuggestList
                searchAreaCondition={this.props.searchAreaCondition}
                handleDeleteSelected={(suggest) => this.handleDeleteSelected(suggest)}
              />
              : ''
            }
            {/* サジェストの入力 */}
            <input className="c-mn-input-text" type="text" ref={this.areaRef} placeholder={this.props.searchAreaCondition === null ? '入力する' : ''} onChange={this.handleChange} value={this.state.word} />
          </div>
          <button className="p-institution-search__modal-search-btn" onClick={this.handleClickNextButton} disabled={this.props.searchAreaCondition === null}>次へ</button>
          <hr className="c-mn-line-second" />
        </div>
        {/* リスト表示エリア(サジェスト候補とメニューの出し分け) */}
        { this.state.suggests.length > 0
          ? <AreaModalSuggestList
            setModal={this.props.setModal}
            suggests={this.state.suggests}
            handleSelected={(suggest) => this.handleSelected(suggest)}
          />
          : <AreaModalMenuList setModal={this.props.setModal} getCurrentArea={this.getCurrentArea}/>
        }
      </div>
    );
  }
}

AreaModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  getCurrentArea: PropTypes.func
};
