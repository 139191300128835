import React from 'react';
import LogInConfirmComponent from '../../../../common/molecules/LogInConfirmComponent';
import hamburger from '../../../../../images/front/hospitals_search/icon/hamburger';
import search from '../../../../../images/front/hospitals_search/icon/search';
import HeaderLogo from '../../atoms/HeaderLogo';
import MypageIcon from '../../atoms/MypageIcon';
import NotificationIcon from '../../molecules/common/Header/NotificationIcon';
import { HeaderMenu, NavMenu, SearchBox } from '../../molecules/common/Header';
import { USER_PATH } from '../../../responsive/constants';
// import DoctorSaveButtonComponent from '../../molecules/common/DoctorSaveButton';

class Header extends LogInConfirmComponent {
  constructor (props) {
    super(props);
    this.state = {
      isActive: false, // ヘッダーメニューの開閉フラグ
      isOpenSearchBox: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenSp = this.handleOpenSp.bind(this);
    this.handleCloseSp = this.handleCloseSp.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.onClickSearchIcon = this.onClickSearchIcon.bind(this);
    this.onClickCloseSearchBox = this.onClickCloseSearchBox.bind(this);
  }

  handleOpen () {
    this.setState({ isActive: true });
    const scrollPositionHeaderMenu = this.scrollTop();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('fixed');
    body.style.top = -scrollPositionHeaderMenu;
  }

  handleClose (event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ isActive: false });
    const scrollPositionHeaderMenu = this.scrollTop();
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('fixed');
    body.style.top = 0;
    window.scrollTo(0, scrollPositionHeaderMenu);
  }

  handleOpenSp () {
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('_menu_is_closed');
    html.classList.add('_menu_is_opened');
  }

  handleCloseSp () {
    const closeOl = document.getElementsByClassName('p-sidemenu__overlay');
    closeOl.classList.remove('_menu_is_opened');
    closeOl.classList.add('_menu_is_closed');
  }

  // scroll位置を取る関数。ブラウザによって取り方が違うようなので全て持ってきてMaxをとる
  scrollTop () {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop);
  }

  onClickSearchIcon () {
    this.setState({
      isOpenSearchBox: !this.state.isOpenSearchBox
    });
  }

  onClickCloseSearchBox () {
    this.setState({
      isOpenSearchBox: false
    });
  }

  renderHamburgerButton () {
    if (this.state.isActive) {
      return (
        <>
          <a className={'p-lower-header__btn-link-close js-btn-menu-close ' + (this.state.isActive ? 'is-open' : '')} onClick={this.handleClose}>
            <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.344595 0.344595C-0.114865 0.804055 -0.114865 1.54899 0.344595 2.00845L5.33615 7L0.344595 11.9916C-0.114865 12.451 -0.114865 13.1959 0.344595 13.6554C0.804055 14.1149 1.54899 14.1149 2.00845 13.6554L7 8.66385L11.9916 13.6554C12.451 14.1149 13.1959 14.1149 13.6554 13.6554C14.1149 13.1959 14.1149 12.451 13.6554 11.9916L8.66385 7L13.6554 2.00845C14.1149 1.54899 14.1149 0.804054 13.6554 0.344595C13.1959 -0.114865 12.451 -0.114865 11.9916 0.344595L7 5.33615L2.00845 0.344595C1.54899 -0.114865 0.804055 -0.114865 0.344595 0.344595Z" fill="#999999" />
            </svg>
            <span>閉じる</span>
          </a>
        </>
      );
    } else {
      return (
        <>
          <a className={'p-lower-header__btn-link js-btn-menu ' + (this.state.isActive ? 'is-open' : '')} onClick={this.handleOpen}>
            <svg className="c-mn-icon-menu" width={15} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="c-mn-icon-menu-path" d="M0.787109 1.42857C0.787109 0.639593 1.4267 0 2.21568 0H19.3585C20.1475 0 20.7871 0.639593 20.7871 1.42857C20.7871 2.21755 20.1475 2.85714 19.3585 2.85714H2.21568C1.4267 2.85714 0.787109 2.21755 0.787109 1.42857Z" fill="black" />
              <path className="c-mn-icon-menu-path" d="M0.787109 10C0.787109 9.21102 1.4267 8.57143 2.21568 8.57143H19.3585C20.1475 8.57143 20.7871 9.21102 20.7871 10C20.7871 10.789 20.1475 11.4286 19.3585 11.4286H2.21568C1.4267 11.4286 0.787109 10.789 0.787109 10Z" fill="black" />
              <path className="c-mn-icon-menu-path" d="M0.787109 18.5714C0.787109 17.7824 1.4267 17.1429 2.21568 17.1429H19.3585C20.1475 17.1429 20.7871 17.7824 20.7871 18.5714C20.7871 19.3604 20.1475 20 19.3585 20H2.21568C1.4267 20 0.787109 19.3604 0.787109 18.5714Z" fill="#000" />
            </svg>
            <span>メニュー</span>
          </a>
        </>
      );
    }
  }

  renderHamburgerButtonSp () {
    if (!this.props.disableHamburgerButton) {
      return (
        <img src={hamburger} width="16" className="p-header-responsive__hamburger js-sidemenu" alt="" />
      );
    }
  }

  render () {
    return (
      <>
        <div className="l-header-responsive">
          <div className="p-header-responsive">
            <p className="p-header-responsive__catch"><span className="p-header-responsive__catch-inner">医師と患者をつなぐ医療・ヘルスケアプラットフォーム<span className="p-header-responsive__catch-inner-add">｜2,000以上の病気の情報と医師・病院情報を掲載</span></span></p>
            <div className="p-header-responsive__inner">
              <div className="p-header-responsive__logo-area">
                <HeaderLogo config={this.props.config} />
              </div>
              <div className="p-header-responsive__right">
                {this.state.isOpenSearchBox
                  ? <SearchBox isOpenSearchBox={this.state.isOpenSearchBox} onClickCloseSearchBox={this.onClickCloseSearchBox} />
                  : <>
                    <NavMenu config={this.props.config} />
                    <ul className="p-header-responsive__btn-list">
                      {!this.isLoggedIn() &&
                        <>
                          <li className="p-header-responsive__btn-list-item"><a href="/forward_to_login" className="c-btn-login-responsive"><span>ログイン</span></a></li>
                          <li className="p-header-responsive__btn-list-item"><a href="/forward_to_signup" className="c-btn-signin-responsive"><span>会員登録</span></a></li>
                        </>
                      }
                      {this.isLoggedIn() &&
                        <li className="p-header-responsive__btn-list-item">
                          <NotificationIcon config={this.props.config} />
                        </li>
                      }
                      {/* <DoctorSaveButtonComponent isLoggedIn={this.isLoggedIn()} /> */}
                      <li className="p-header-responsive__btn-list-item">
                        <div className="c-search-icon">
                          <img src={this.props.config.assetHost + search} onClick={this.onClickSearchIcon} />
                        </div>
                      </li>
                      {this.isLoggedIn() &&
                        <li className="p-header-responsive__btn-list-item  p-header-responsive__pc-only">
                          <div className="p-header-responsive__menu-member">
                            <a className="c-mypage-icon" href={USER_PATH}>
                              <MypageIcon />
                            </a>
                          </div>
                        </li>
                      }
                    </ul>
                    <div className="p-header-responsive__hamburger-wrap">
                      <img src={this.props.config.assetHost + hamburger} width="16" className="p-header-responsive__hamburger" alt="" onClick={this.handleOpenSp} />
                    </div>
                    {/* <div className="p-header-responsive__hamburger-wrap-pc">
                      <ul className="p-lower-header__btn">
                        <li className="p-lower-header__btn-item">
                          {this.renderHamburgerButton()}
                        </li>
                      </ul>
                    </div> */}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <HeaderMenu isActive={this.state.isActive} isLoggedIn={this.isLoggedIn()} />
      </>
    );
  }
}

export default Header;
