export default class Areas {
  static areas = [
    { code: '01', name: '北海道・東北' },
    { code: '02', name: '関東' },
    { code: '03', name: '北陸・中部' },
    { code: '04', name: '近畿' },
    { code: '05', name: '中国・四国' },
    { code: '06', name: '九州・沖縄' },
    { code: 'current_area', name: '現在地周辺' }
  ];

  static all () {
    return this.areas;
  }

  static findByCode (code) {
    return this.areas.find(area => area.code === code);
  }
}
