import React from 'react';
import {
  AbstractComponent
} from '../../../../common/atoms/index';

export default class PrevSearchCondition extends AbstractComponent {
  renderPrevSearchConditionUrl () {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');

    for (const cookie of cookiesArray) {
      const cookieArray = cookie.split('=');
      if (cookieArray[0].match(/search_condition_query/)) {
        const text = '/hospitals/search?' + decodeURIComponent(cookieArray[1]);
        return (
          text
        );
      }
    }
    return null;
  }

  renderPrevSearchConditionLabel () {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');

    for (const cookie of cookiesArray) {
      const cookieArray = cookie.split('=');
      if (cookieArray[0].match(/search_condition_label/)) {
        return (
          decodeURIComponent(cookieArray[1])
        );
      }
    }
    return null;
  }

  render () {
    return (
      this.renderPrevSearchConditionLabel()
        ? <div className="pg-institution-search-top__last-time-search-link">
          <a href={this.renderPrevSearchConditionUrl()}>
            <div className="pg-institution-search-top__last-time-search">
              <div className="pg-institution-search-top__last-time-search-title">
                <svg width="14" height="14" viewbox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.99992 14C8.24979 14 9.43487 13.6751 10.5209 13.035C11.5631 12.4234 12.4023 11.5774 13.0149 10.521C13.6408 9.45019 13.958 8.26415 13.958 6.99905C13.958 5.73299 13.6408 4.54886 13.0149 3.47904C12.4043 2.42352 11.565 1.57662 10.5209 0.963119C9.43582 0.323898 8.25074 0 6.99992 0C6.053 0 5.14323 0.182907 4.29633 0.543958C3.58757 0.846897 2.94263 1.25177 2.37867 1.74809C2.29198 1.82431 2.17956 1.86717 2.06429 1.86717C1.93664 1.86717 1.82042 1.81859 1.72801 1.72714L0.813478 0.812602C0.722024 0.722101 0.600086 0.673517 0.476243 0.673517C0.415274 0.673517 0.3524 0.684948 0.294289 0.709717C0.116145 0.78307 -7.72476e-05 0.957403 -7.72476e-05 1.14984V5.6644C-7.72476e-05 5.92733 0.213314 6.14072 0.476243 6.14072H4.99081C5.18324 6.14072 5.35662 6.0245 5.43092 5.84635C5.50428 5.66821 5.46331 5.46339 5.32709 5.32716L3.66473 3.66385C3.56947 3.56954 3.51993 3.43903 3.5266 3.30471C3.53231 3.17039 3.59614 3.04464 3.69903 2.95985C4.09247 2.635 4.53068 2.36922 4.99938 2.16916C5.63193 1.89671 6.30545 1.75953 6.99992 1.75953C7.94494 1.75953 8.82995 1.99578 9.63016 2.46257C10.4294 2.92746 11.0706 3.56954 11.5373 4.36976C12.0032 5.16903 12.2394 6.05498 12.2394 6.99905C12.2394 7.94692 12.0032 8.83097 11.5373 9.62929C11.0706 10.4286 10.4304 11.0706 9.63016 11.5374C8.82995 12.0023 7.94494 12.2386 6.99992 12.2386C5.85771 12.2386 4.85648 11.9232 3.94004 11.2754C3.11029 10.6867 2.52918 9.96176 2.16432 9.05866C2.09192 8.87861 1.91664 8.76048 1.7223 8.76048H0.891595C0.737267 8.76048 0.591513 8.83574 0.502917 8.96244C0.413369 9.08819 0.391459 9.25014 0.441949 9.39589C0.708688 10.1466 1.10308 10.8391 1.61274 11.4526C2.26625 12.2386 3.05599 12.8635 3.96005 13.3065C4.90697 13.7666 5.92916 14 6.99992 14Z" fill="#CCCCCC"></path>
                </svg>
                <p>前回の検索条件</p>
              </div>
              <div className="pg-institution-search-top__last-time-search-conditions">
                <p>{this.renderPrevSearchConditionLabel()}</p>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15">
                  <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                </svg>
              </div>
              <hr className="c-mn-line-second"></hr>
            </div>
          </a>
        </div>
        : null
    );
  }
}
