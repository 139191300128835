import React from 'react';
import BaseForm from './BaseForm';
import SearchModal from '../../molecules/hospitals/SearchModal';
import Options from '../../../../common/atoms/hospitals/Options';

export default class TopForm extends BaseForm {
  render () {
    return (
      <>
        <form>
          <div className="pg-institution-search-top__search-form">
            <div className="pg-institution-search-top__search-form-type">
              {/* <label className="pg-institution-search-top__search-form-label" htmlFor="">病名/診療科/検査名/治療名</label> */}
              <label className="pg-institution-search-top__search-form-label" htmlFor="">診療科/検査名/治療名</label>
              <div className="p-institution-search__modal-search">
                <div className="p-institution-search__modal-search-tag">
                  <ul className="c-mn-tag-list">
                    {this.state.condition.treatments.map(treatment => {
                      return (
                        <li key={treatment.code}>
                          <div className="c-mn-tag">
                            {treatment.name}&nbsp;
                            <span style={{ cursor: 'pointer' }} onClick={event => this.handleRemoveSuggestTreatment(event, treatment)}>
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="white"></circle><path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z" fill="#A3E3E3"></path></svg>
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/*
                <input
                  name=""
                  value={this.state.values.treatment}
                  type="text"
                  className="c-mn-input-text js-suggest-open"
                  placeholder={this.state.condition.treatments.length === 0 ? '病名/診療科/検査名/治療名' : ''}
                  onChange={this.handleChangeValueTreatment}
                />
                */}
                <input
                  name=""
                  value={this.state.values.treatment}
                  type="text"
                  className="c-mn-input-text js-suggest-open"
                  placeholder={this.state.condition.treatments.length === 0 ? '診療科/検査名/治療名' : ''}
                  onChange={this.handleChangeValueTreatment}
                />
              </div>
              {this.state.showSuggest.treatments ? this.renderSuggestTreatments() : null}
            </div>
            <div className="pg-institution-search-top__search-form-area">
              <label className="pg-institution-search-top__search-form-label" htmlFor="">エリア/駅</label>
              <div className="p-institution-search__modal-search">
                <div className="p-institution-search__modal-search-tag">
                  <ul className="c-mn-tag-list">
                    {this.state.condition.area
                      ? <li key={this.state.condition.area.code}>
                        <div className="c-mn-tag">
                          {this.state.condition.area.name}&nbsp;
                          <span style={{ cursor: 'pointer' }} onClick={event => this.handleRemoveSuggestArea(event)}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="white"></circle><path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z" fill="#A3E3E3"></path></svg>
                          </span>
                        </div>
                      </li>
                      : null
                    }
                  </ul>
                </div>
                <input
                  name=""
                  value={this.state.values.area}
                  type="text"
                  className="c-mn-input-text js-suggest-open"
                  placeholder={this.state.condition.area === null ? 'エリア/駅' : ''}
                  onChange={this.handleChangeValueArea}
                />
                {this.state.showSuggest.areas && this.state.values.area ? this.renderSuggestAreas() : null}
              </div>
            </div>
            <div className="pg-institution-search-top__search-form-detail">
              <label className="pg-institution-search-top__search-form-label" htmlFor="">詳細条件</label>
              <div className="p-institution-search__search-form-fixed-detail-tag">
                <ul className="c-mn-tag-list">
                  {this.state.condition.options.map(option => {
                    return (
                      <div key={option.type} className="c-mn-tag">
                        {Options.findByName(option.type).value}&nbsp;
                        <a
                          href=""
                          onClick={this.handleRemoveOption}
                        >
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="6" cy="6" r="6" fill="white">
                            </circle>
                            <path fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z"
                              fill="#A3E3E3">
                            </path>
                          </svg>
                        </a>
                      </div>
                    );
                  })}
                </ul>
                <a
                  className="p-institution-search__search-form-fixed-detail-link js-modal-detail-open"
                  href=""
                  onClick={this.handleOpenModal}
                >変更&nbsp;
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15">
                    <path className="c-mn-icon-arrow-path"
                      d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z">
                    </path>
                  </svg>
                </a>
              </div>
            </div>
            <button
              className="pg-institution-search-top__search-form-submit"
              onClick={this.handleSubmit}
            >
              <svg className="c-mn-icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.95 21.14" width="15">
                <path className="c-mn-icon-search-path"
                  d="M20.08,21.54a1.26,1.26,0,0,1-.89-.37l-3.93-3.93-.27.19a9.21,9.21,0,0,1-5.26,1.63,9.33,9.33,0,1,1,9.33-9.33,9.25,9.25,0,0,1-1.78,5.48l-.2.27,3.9,3.9a1.27,1.27,0,0,1-.9,2.16ZM9.73,2.45A7.28,7.28,0,1,0,17,9.73,7.29,7.29,0,0,0,9.73,2.45Z"
                  transform="translate(-0.4 -0.4)">
                </path>
              </svg>
              <span>検索</span>
            </button>
          </div>
          <div className="pg-institution-search-top__search-form-bottom">
            <div className="c-mn-checkbox-btn c-mn-checkbox-btn--no-border">
              <input
                className="c-mn-checkbox-btn__checkbox"
                id="checkbox0101"
                name="is_free_mail_consultation_available"
                value="医師に無料メール相談可能"
                type="checkbox"
                checked={this.findOption('is_free_mail_consultation_available')}
                onChange={this.handleChangeOption}
              />
              <label className="c-mn-checkbox-btn__label" htmlFor="checkbox0101">
                <svg className="c-mn-icon-check" width="29" height="20" viewBox="0 0 29 20" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path className="c-mn-icon-check-path"
                    d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z"
                    fill="#000">
                  </path>
                  <path className="c-mn-icon-check-path"
                    d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z"
                    fill="#000">
                  </path>
                </svg>
                医師にメール相談ができる病院
              </label>
            </div>
            {this.renderPrevSearchConditionLabel()
              ? <>
                <p className="pg-institution-search-top__search-form-last-time">
                  <a className="pg-institution-search-top__search-form-last-time-link" href={this.renderPrevSearchConditionUrl()}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.99992 14C8.24979 14 9.43487 13.6751 10.5209 13.035C11.5631 12.4234 12.4023 11.5774 13.0149 10.521C13.6408 9.45019 13.958 8.26415 13.958 6.99905C13.958 5.73299 13.6408 4.54886 13.0149 3.47904C12.4043 2.42352 11.565 1.57662 10.5209 0.963119C9.43582 0.323898 8.25074 0 6.99992 0C6.053 0 5.14323 0.182907 4.29633 0.543958C3.58757 0.846897 2.94263 1.25177 2.37867 1.74809C2.29198 1.82431 2.17956 1.86717 2.06429 1.86717C1.93664 1.86717 1.82042 1.81859 1.72801 1.72714L0.813478 0.812602C0.722024 0.722101 0.600086 0.673517 0.476243 0.673517C0.415274 0.673517 0.3524 0.684948 0.294289 0.709717C0.116145 0.78307 -7.72476e-05 0.957403 -7.72476e-05 1.14984V5.6644C-7.72476e-05 5.92733 0.213314 6.14072 0.476243 6.14072H4.99081C5.18324 6.14072 5.35662 6.0245 5.43092 5.84635C5.50428 5.66821 5.46331 5.46339 5.32709 5.32716L3.66473 3.66385C3.56947 3.56954 3.51993 3.43903 3.5266 3.30471C3.53231 3.17039 3.59614 3.04464 3.69903 2.95985C4.09247 2.635 4.53068 2.36922 4.99938 2.16916C5.63193 1.89671 6.30545 1.75953 6.99992 1.75953C7.94494 1.75953 8.82995 1.99578 9.63016 2.46257C10.4294 2.92746 11.0706 3.56954 11.5373 4.36976C12.0032 5.16903 12.2394 6.05498 12.2394 6.99905C12.2394 7.94692 12.0032 8.83097 11.5373 9.62929C11.0706 10.4286 10.4304 11.0706 9.63016 11.5374C8.82995 12.0023 7.94494 12.2386 6.99992 12.2386C5.85771 12.2386 4.85648 11.9232 3.94004 11.2754C3.11029 10.6867 2.52918 9.96176 2.16432 9.05866C2.09192 8.87861 1.91664 8.76048 1.7223 8.76048H0.891595C0.737267 8.76048 0.591513 8.83574 0.502917 8.96244C0.413369 9.08819 0.391459 9.25014 0.441949 9.39589C0.708688 10.1466 1.10308 10.8391 1.61274 11.4526C2.26625 12.2386 3.05599 12.8635 3.96005 13.3065C4.90697 13.7666 5.92916 14 6.99992 14Z"
                        fill="#CCCCCC">
                      </path>
                    </svg>
                    前回の検索条件<span>{this.renderPrevSearchConditionLabel()}</span>
                  </a>
                </p>
              </>
              : null
            }
          </div>
        </form>
        {this.state.isModalOpen
          ? <SearchModal
            handleOpenModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            handleChangeOption={this.handleChangeOption}
            findOption={this.findOption}
            handleClearOptions={this.handleClearOptions}
            handleSubmit={this.handleSubmit}
          />
          : null
        }
      </>
    );
  }
}
