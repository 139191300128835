import React from 'react';
import BaseForm from './BaseForm';
import SearchModal from '../../molecules/hospitals/SearchModal';
import Options from '../../../../common/atoms/hospitals/Options';

export default class SearchForm extends BaseForm {
  componentDidMount () {
    super.componentDidMount();

    let start = 0;
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY;
      if (scrollY > 77) {
        if (scrollY >= start) {
          this.setState({ showForm: false });
        } else {
          this.setState({ showForm: true });
        }
        start = scrollY;
      } else {
        this.setState({ showForm: false });
      }
    });
  }

  render () {
    return (
      <>
        <div className={ this.state.showForm ? 'p-institution-search__search-form-fixed-wrap is-hide' : 'p-institution-search__search-form-fixed-wrap' }>
          <form>
            <div className="p-institution-search__search-form-fixed">
              <div className="p-institution-search__search-form-fixed-type">
                {/* <label className="p-institution-search__search-form-fixed-label" htmlFor="">病名/診療科/検査名/治療名</label> */}
                <label className="p-institution-search__search-form-fixed-label" htmlFor="">診療科/検査名/治療名</label>
                <div className="p-institution-search__modal-search">
                  <div className="p-institution-search__modal-search-tag">
                    <ul className="c-mn-tag-list">
                      {this.state.condition.treatments.map(treatment => {
                        return (
                          <li key={treatment.code}>
                            <div className="c-mn-tag">
                              {treatment.name}
                              <span style={{ cursor: 'pointer' }} onClick={event => this.handleRemoveSuggestTreatment(event, treatment)}>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="white"></circle><path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z" fill="#A3E3E3"></path></svg>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/*
                  <input
                    name=""
                    value={this.state.values.treatment}
                    type="text"
                    className="c-mn-input-text js-suggest-open--fixed"
                    placeholder={this.state.condition.treatments.length === 0 ? '病名/診療科/検査名/治療名' : ''}
                    onChange={this.handleChangeValueTreatment}
                  />
                  */}
                  <input
                    name=""
                    value={this.state.values.treatment}
                    type="text"
                    className="c-mn-input-text js-suggest-open--fixed"
                    placeholder={this.state.condition.treatments.length === 0 ? '診療科/検査名/治療名' : ''}
                    onChange={this.handleChangeValueTreatment}
                  />
                </div>
                {this.state.showSuggest.treatments ? this.renderSuggestTreatments() : null}
              </div>
              <div className="p-institution-search__search-form-fixed-area">
                <label className="p-institution-search__search-form-fixed-label" htmlFor="">エリア/駅</label>
                <div className="p-institution-search__modal-search">
                  <div className="p-institution-search__modal-search-tag">
                    <ul className="c-mn-tag-list">
                      {this.state.condition.area
                        ? <li key={this.state.condition.area.code}>
                          <div className="c-mn-tag">
                            {this.state.condition.area.name}
                            <span style={{ cursor: 'pointer' }} onClick={event => this.handleRemoveSuggestArea(event)}>
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="white"></circle><path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z" fill="#A3E3E3"></path></svg>
                            </span>
                          </div>
                        </li>
                        : null
                      }
                    </ul>
                  </div>
                  <input
                    name=""
                    value={this.state.values.area}
                    type="text"
                    className="c-mn-input-text js-suggest-open--fixed"
                    placeholder={this.state.condition.area === null ? 'エリア/駅' : ''}
                    onChange={this.handleChangeValueArea}
                  />
                </div>
                {this.state.showSuggest.areas ? this.renderSuggestAreas() : null}
              </div>
              <div className="p-institution-search__search-form-fixed-detail">
                <label className="p-institution-search__search-form-fixed-label" htmlFor="">詳細条件</label>
                <div className="p-institution-search__search-form-fixed-detail-tag">
                  <ul className="c-mn-tag-list">
                    {this.state.condition.options.map(option => {
                      return (
                        <div key={option.type} className="c-mn-tag">
                          <a
                            href=""
                            onClick={this.handleRemoveOption}
                          >
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6" cy="6" r="6" fill="white">
                              </circle>
                              <path fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z"
                                fill="#A3E3E3">
                              </path>
                            </svg>
                          </a>
                          {Options.findByName(option.type).value}
                        </div>
                      );
                    })}
                  </ul>
                  <a
                    className="p-institution-search__search-form-fixed-detail-link js-modal-detail-open"
                    href=""
                    onClick={this.handleOpenModal}
                  >変更&nbsp;
                    <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"
                      width="15">
                      <path className="c-mn-icon-arrow-path"
                        d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
              <button
                className="p-institution-search__search-form-fixed-submit"
                onClick={this.handleSubmit}
              >
                <span>検索</span>
              </button>
            </div>
          </form>
        </div>
        {this.state.isModalOpen
          ? <SearchModal
            handleOpenModal={this.handleOpenModal}
            handleCloseModal={this.handleCloseModal}
            handleChangeOption={this.handleChangeOption}
            findOption={this.findOption}
            handleClearOptions={this.handleClearOptions}
            handleSubmit={this.handleSubmit}
          />
          : null
        }
      </>
    );
  }
}
