import React from 'react';
import NearbyHospitalComponent from '../../../../common/organisms/NearbyHospitalComponent';
import SearchCondition from '../../../../common/atoms/hospitals/SearchCondition';
import Imgix from '../../../../common/atoms/Imgix';

export default class NearbyHospitalSearch extends NearbyHospitalComponent {
  componentDidMount () {
    const url = new URL(window.location);
    const queryParameter = url.searchParams;

    // API が「医師に無料メール相談可能」、「病院名から探す」の検索条件を考慮できていないため、
    // 近くのエリアの他の病院の検索条件から除外する。
    // TODO: API を修正し、近くのエリアの他の病院の検索条件に、
    // 「医師に無料メール相談可能」の検索条件を含めるようにする
    queryParameter.delete('is_free_mail_consultation_available');
    queryParameter.delete('institution_name_or_doctor_name');

    let searchnearbyhospitalparameter = url.search;

    if (!searchnearbyhospitalparameter.length) {
      this.state.condition.treatments = this.props.searchCondition.treatmentConditions;
      this.state.condition.area = this.props.searchCondition.areaCondition;
      this.state.condition.options = this.props.searchCondition.options;
      this.state.condition.institutionNameOrDoctorName = this.props.searchCondition.institutionNameOrDoctorName;

      const condition = this.state.condition;
      const searchCondition = new SearchCondition(condition);
      // URL インスタンスを作成したいので dummy で https://example.com を指定している。
      const url = new URL(searchCondition.url(), 'https://example.com');
      searchnearbyhospitalparameter = url.search;
    }

    this.fetchNearbyHospitals(searchnearbyhospitalparameter);
  }

  render () {
    return (
      <>
        { this.state.nearbyHospitals.length
          ? <>
            <div className="p-institution-detail-nearby-institution">
              <h2 className="c-mn-title-2">近くのエリアの他の病院</h2>
              <ul className="c-card-group">
                {this.state.nearbyHospitals.map((hospital) => {
                  return (
                    <li className="c-card-group-list" key={ hospital.id }>
                      <div className="p-institution-detail-nearby-institution-card">
                        <a href={`/hospitals/${hospital.code}`} className='c-mn-card c-card-link'>
                          <div className="c-mn-card-contents">
                            <div className="c-mn-card-avatar">
                              <div className="c-mn-avatar-entity-wrap-m">
                                { hospital.photos.length
                                  ? <div className="c-mn-avatar-entity" style={{ backgroundImage: `url(${Imgix.UriConverter(this.props.donutImgixDomain, hospital.photos[0].image_url, { fm: 'webp', fit: 'crop', w: 120, h: 120 })})` }}></div>
                                  : <div className="c-mn-avatar-entity" style={{ backgroundImage: `url(${this.props.defaultHospitalImage})` }}></div>
                                }
                              </div>
                            </div>
                            <div className="c-mn-card-main">
                              <div className="c-mn-card-contents__name">
                                <p>
                                  { hospital.display_name }
                                </p>
                              </div>
                              { hospital.departments.length
                                ? <div className="c-mn-card-contents__department">
                                  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" className="c-mn-icon-stethscope" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.3048 9.9962C17.9324 10.0166 16.7228 10.8906 16.2881 12.1759C15.8535 13.4612 16.2886 14.8773 17.3733 15.7076C16.5941 17.0051 15.2778 17.9 13.7721 18.1562C12.2192 18.3655 10.6955 17.6118 9.93561 16.2585C10.8639 15.6705 11.4682 14.6951 11.5756 13.6114C14.0372 11.7292 16.8635 7.39984 17.0319 4.80041C17.1722 2.5688 15.5384 1.21291 13.1829 0.952811C12.7855 0.230723 11.9469 -0.138366 11.1373 0.0525591C10.3277 0.243484 9.74857 0.946925 9.72471 1.76834C9.70086 2.58975 10.2382 3.32479 11.0354 3.56119C11.8326 3.79759 12.6913 3.47654 13.1299 2.77811C13.652 2.74442 14.1662 2.91761 14.5586 3.25935C14.9509 3.60108 15.1891 4.08315 15.2204 4.59879C15.1876 6.56414 12.2272 11.3059 9.74854 12.6541C9.21637 13.0341 8.49729 13.0341 7.96512 12.6541C5.43126 10.8115 3.55124 8.22616 2.59306 5.26673C2.26412 3.9524 2.72089 3.0536 4.58537 2.78273C4.91417 3.31266 5.49767 3.63651 6.12715 3.63843C7.03035 3.6481 7.80749 3.00999 7.96245 2.13148C8.11741 1.25298 7.6045 0.393091 6.75116 0.100773C5.89782 -0.191544 4.95565 0.169895 4.52613 0.95435C2.37637 1.20521 0.670898 2.38719 0.670898 4.55416C0.670898 7.09203 3.61727 11.6707 6.14118 13.6145C6.33239 14.7392 6.98563 15.7361 7.94642 16.3694C8.79939 18.5651 10.9396 20.0106 13.3216 19.9999C15.7666 19.885 17.9658 18.4968 19.0974 16.354C20.2627 16.4145 21.3701 15.8484 21.9925 14.8739C22.6148 13.8995 22.655 12.669 22.0974 11.657C21.5398 10.6451 20.4716 10.0098 19.3048 9.9962ZM19.3043 14.5457C18.7434 14.5532 18.2335 14.2254 18.0135 13.7159C17.7936 13.2064 17.9072 12.6163 18.3012 12.222C18.6951 11.8277 19.2913 11.7075 19.8103 11.9177C20.3294 12.1278 20.6683 12.6267 20.6684 13.1806C20.6718 13.9285 20.0619 14.5382 19.3043 14.5441V14.5457Z" fill="#000" className="c-mn-icon-stethscope-path"></path>
                                  </svg>
                                  <p>
                                    {
                                      hospital.departments.map((department) => {
                                        return (
                                          <span key={ department.id }>
                                            { department.name }
                                          </span>
                                        );
                                      })
                                    }
                                  </p>
                                </div>
                                : null
                              }

                              {
                                hospital.display_address
                                  ? <div className="c-mn-card-contents__address">
                                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" className="c-mn-icon-pin" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd" clipRule="evenodd" d="M7.15592 0C3.65789 0 0.489258 2.832 0.489258 6.336C0.489258 9.84 3.38024 14.008 7.15592 20C10.9316 14.008 13.8226 9.832 13.8226 6.336C13.8226 2.84 10.6547 0 7.15592 0ZM7.15672 9.16798C5.80971 9.14072 4.73663 8.00994 4.75378 6.63583C4.77094 5.26172 5.87189 4.15918 7.21915 4.16691C8.5664 4.17464 9.65512 5.28976 9.65711 6.66398C9.65131 7.33431 9.38457 7.97481 8.9156 8.44445C8.44663 8.91409 7.8139 9.17437 7.15672 9.16798Z" fill="#000" className="c-mn-icon-pin-path"></path>
                                    </svg>
                                    <p>
                                      { hospital.display_address }
                                    </p>
                                  </div>
                                  : null
                              }

                              {hospital.transportation_display_text
                                ? <div className="c-mn-card-contents__train">
                                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" className="c-mn-icon-train" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.64751 17.0585L1.00231 20H2.69738L3.73821 18.9231H14.2714L15.3123 20H17.0073L14.3607 17.0585C16.0364 16.6043 17.2001 15.0308 17.1858 13.2385V3.82C17.172 1.7174 15.5289 0.015932 13.4968 0H4.50397C2.47475 0.0201091 0.836103 1.72036 0.822397 3.82V13.2385C0.808057 15.0308 1.97177 16.6043 3.64751 17.0585ZM2.70437 13.7724C2.70437 13.0383 3.27954 12.4432 3.98905 12.4432C4.69856 12.4432 5.27374 13.0383 5.27374 13.7724C5.22262 14.467 4.66303 15.0036 3.9898 15.0036C3.31657 15.0036 2.75697 14.467 2.70585 13.7724H2.70437ZM8.94894 17.8585H4.66815C4.86625 17.6439 5.0385 17.4053 5.18113 17.1478H12.803C12.9162 17.424 13.0922 17.6678 13.316 17.8585H8.94894ZM14.005 15.0155C13.3187 15.0388 12.7438 14.4825 12.7203 13.7724C12.7203 13.0383 13.2955 12.4432 14.005 12.4432C14.7145 12.4432 15.2897 13.0383 15.2897 13.7724C15.2663 14.4807 14.6941 15.0362 14.0095 15.0155H14.005ZM6.47314 1.69234H11.5286C11.8177 1.7191 12.0392 1.96974 12.0392 2.27003C12.0392 2.57033 11.8177 2.82096 11.5286 2.84772H6.47314C6.18407 2.82096 5.96254 2.57033 5.96254 2.27003C5.96254 1.96974 6.18407 1.7191 6.47314 1.69234ZM2.10538 5.51394C2.08909 5.06176 2.25492 4.62288 2.56331 4.30205C2.8717 3.98123 3.29496 3.80724 3.73205 3.82163H14.2653C14.7024 3.80724 15.1256 3.98123 15.434 4.30205C15.7424 4.62288 15.9082 5.06176 15.892 5.51394V9.33393C15.9087 9.78623 15.743 10.2254 15.4345 10.5463C15.126 10.8673 14.7025 11.0411 14.2653 11.0262H3.73205C3.29483 11.0411 2.8713 10.8673 2.56282 10.5463C2.25433 10.2254 2.08864 9.78623 2.10538 9.33393V5.51394Z" fill="black" className="c-mn-icon-train-path"></path>
                                  </svg>
                                  <p>
                                    { hospital.transportation_display_text }
                                  </p>
                                </div>
                                : null
                              }

                              { hospital.telephone
                                ? <div className="c-mn-card-contents__phone">
                                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" className="c-mn-icon-phone" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.5764 19.0983L12.5698 19.6499C6.68103 22.6835 -3.53136 5.10211 2.20914 1.72372L3.1744 1.24109L6.30803 6.61894L5.34691 7.10156C3.60256 8.13577 7.67593 15.2373 9.46372 14.272L10.4441 13.7205L13.5764 19.0983ZM5.30326 0L3.88571 0.827361L7.01934 6.20521L8.43758 5.37785L5.30326 0ZM12.571 12.4795L11.1534 13.3069L14.287 18.6847L15.7053 17.8573L12.571 12.4795Z" fill="#000" className="c-mn-icon-phone-path"></path>
                                  </svg>
                                  <p>
                                    { hospital.telephone }
                                  </p>
                                </div>
                                : null
                              }
                            </div>
                          </div>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="c-mn-icon-arrow">
                            <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"></path>
                          </svg>
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="c-button-position-wrap--center">
                <a href={`${window.location.origin}/hospitals/search${this.state.resultSearchQuery}`} className="c-mn-btn--third" rel="nofollow">
                  <span>この条件で再検索</span>
                </a>
              </div>
            </div>
          </>
          : null }
      </>
    );
  }
}
