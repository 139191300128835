// [駅/路線] 路線選択モーダル
import React from 'react';
import PropTypes from 'prop-types';

export default class LineSelectModal extends React.Component {
  prevModal () {
    this.props.setModal('stationPrefectureSelect');
    this.props.clearLines();
  }

  handleClick (line) {
    this.props.setModal('stationSelect');
    this.props.setLineCode(line.line_code);
    this.props.setLineName(line.line_name);
    this.props.fetchStations(this.props.stationPrefectureCode, line.line_code);
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">駅/路線</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {this.props.lines.map((line) => {
            return (
              <li className="c-mn-sel-list" key={line.line_code}>
                <a onClick={() => this.handleClick(line)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{line.line_name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

LineSelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  setLineCode: PropTypes.func,
  setLineName: PropTypes.func,
  lineCode: PropTypes.string,
  clearLines: PropTypes.func,
  fetchStations: PropTypes.func,
  stationPrefectureCode: PropTypes.string,
  lines: PropTypes.array
};

LineSelectModal.defaultProps = {
  lines: []
};
