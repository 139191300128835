export default class Options {
  static list = [
    {
      id: 'option_group_1',
      name: '医師に無料メール相談ができる病院',
      items: [
        {
          id: 'checkbox0101',
          name: 'is_free_mail_consultation_available',
          value: '医師に無料メール相談可能'
        }
      ]
    }
  ];

  static all () {
    return this.list;
  }

  static findByName (name) {
    return this.list.map(group => group.items).flat().find(item => item.name === name);
  }
}
