export default class SearchCondition {
  constructor (condition) {
    this.pathname = '/hospitals/search';
    this.condition = condition;
  }

  url () {
    return `${this.pathname}?${this.searchParams(this.condition)}`;
  }

  searchParams (condition) {
    const params = [];
    // 病名/診療科/症状/治療名の検索条件
    if (condition.treatments) {
      const treatments = condition.treatments;
      treatments.forEach(treatment => {
        if (!treatment.type || !treatment.code) {
          return;
        }

        if (treatment.type === 'department') {
          // 診療科はdepartment_code[]
          params.push(`${treatment.type}_code[]=${treatment.code}`);
        } else {
          // 病名/症状/治療名はtag_code[]
          params.push(`tag_code[]=${treatment.code}`);
        }
      });
    }
    // エリア/駅の検索条件
    if (condition.area) {
      const area = condition.area;
      if (area.code === 'current_area') {
        params.push(`${area.code}=true`);
        params.push(`latitude=${area.latitude}`);
        params.push(`longitude=${area.longitude}`);
      }
      if (!area.type || !area.code) {
        return;
      }

      if (area.type === 'station') {
        params.push(`${area.type}_code[]=${area.code}`);
      } else {
        params.push(`${area.type}_code=${area.code}`);
      }
    }
    // その他の検索条件
    // type = パラメータ, code = bool値
    if (condition.options && condition.options.length > 0) {
      condition.options.forEach((option) => {
        if (option.code) {
          params.push(`${option.type}=${option.code}`);
        }
      });
    }
    // 病院名、医師名検索
    if (condition.institutionNameOrDoctorName) {
      params.push(`institution_name_or_doctor_name=${encodeURI(condition.institutionNameOrDoctorName)}`);
    }

    return params.join('&');
  }
}
