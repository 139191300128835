import React from 'react';
import PropTypes from 'prop-types';

// エリア関連のサジェストリスト
export default function AreaModalSuggestList (props) {
  return (
    <ul className="c-mn-sel-list-group">
      { props.suggests.map((suggest) => {
        return (
          <li className="c-mn-sel-list" key={suggest.code}>
            <a onClick={() => props.handleSelected(suggest)}>
              <div className="c-mn-sel-txt">
                <p className="c-mn-sel-title">{suggest.name}</p>
              </div>
              <svg className="p-institution-search__modal-icon" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="11" y="0.0400391" width="2" height="10" rx="1" transform="rotate(90 11 0.0400391)" fill="#27B1DB"/><rect x="1" y="0.0400391" width="2" height="10" rx="0.999999" fill="#27B1DB"/><rect x="0.959961" y="1.49414" width="2.11308" height="11.727" rx="1.05654" transform="rotate(-45 0.959961 1.49414)" fill="#27B1DB"/></svg>
            </a>
          </li>
        );
      })}
    </ul>
  );
}

AreaModalSuggestList.propTypes = {
  suggests: PropTypes.array,
  handleSelected: PropTypes.func
};
