// エリア選択後の都道府県選択モーダル
import * as React from 'react';
import PropTypes from 'prop-types';
import Prefectures from '../../../../../../common/atoms/Prefectures';

export default class PrefectureSelectModal extends React.Component {
  handleClick (prefecture) {
    if (/^A/.test(prefecture.code)) {
      // 都道府県コードがAから始まる場合、エリアで検索条件を設定する
      const areaCode = prefecture.code.replace('A', '0');
      const condition = { type: 'area', code: areaCode, name: prefecture.name };
      this.props.setSearchAreaCondition(condition);

      if (this.props.previousModal === 'detail') {
        // 詳細条件モーダルからエリアモーダルを開いた場合
        this.props.setModal(this.props.previousModal);
      } else if (this.props.previousModal === 'areaSearchForm') {
        // 治療支援検索TOP下部のエリア/駅から探すから遷移してきた場合は選択した駅で検索結果画面へ遷移する。
        location.href = this.props.searchSelectConditionUrl('area', condition);
      } else {
        // エリア/駅モーダルを閉じる
        this.props.setModal(null);
      }
    } else {
      // 都道府県を選択した場合
      this.props.setModal('citySelect');
      this.props.setPrefectureCode(prefecture.code);
      this.props.setPrefectureName(prefecture.name);
      this.props.fetchCities(prefecture.code);
    }
  }

  prevModal () {
    this.props.setModal('areaSelect');
  }

  render () {
    const prefectures = this.props.areaCode ? Prefectures.findByAreaCode(this.props.areaCode) : [];

    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">都道府県</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {prefectures.map((prefecture) => {
            return (
              <li className="c-mn-sel-list" key={`area_prefecture_${prefecture.code}`}>
                <a onClick={() => this.handleClick(prefecture)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{prefecture.name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

PrefectureSelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  setPrefectureCode: PropTypes.func,
  setPrefectureName: PropTypes.func,
  setSearchAreaCondition: PropTypes.func,
  areaCode: PropTypes.string,
  prefectureCode: PropTypes.string,
  previousModal: PropTypes.string,
  fetchCities: PropTypes.func,
  searchSelectConditionUrl: PropTypes.func
};
