import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../../../atoms/SearchIcon';

export default class SearchBox extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      searchWord: ''
    };
    this.onChangeSearchWord = this.onChangeSearchWord.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  onChangeSearchWord (e) {
    this.setState({
      searchWord: e.currentTarget.value
    });
  }

  onClickSearch (e) {
    e.preventDefault();
    location.href = `/diseases/search?value=${encodeURIComponent(this.state.searchWord)}`;
  }

  formClassName () {
    return this.props.isOpenSearchBox ? '_is_open' : '';
  }

  render () {
    return (
      <div className={`p-header-responsive__search js_search ${this.formClassName()}`}>
        <form className="p-header-responsive__search__form">
          <div className="p-header-responsive__search_inner">
            <div className="p-header-responsive__input">
              <input className="p-header-responsive__search__form__input" placeholder="メディカルノートを検索" type="search" value={this.state.searchWord} onChange={this.onChangeSearchWord}/>
              <div className="p-header-responsive__pc-only">
                <SearchIcon />
              </div>
            </div>
            <a className="p-header-responsive__search_button" onClick={this.onClickSearch}>
              <SearchIcon />
            </a>
            <div className="p-header-responsive__submit">
              <button type="submit" onClick={this.onClickSearch}>検索</button>
            </div>
            <div className="p-header-responsive__cancel">
              <a className="js_search_close" onClick={this.props.onClickCloseSearchBox}>キャンセル</a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SearchBox.propTypes = {
  isOpenSearchBox: PropTypes.bool.isRequired,
  onClickCloseSearchBox: PropTypes.func.isRequired
};
