// 病名 ひらがな五十音順(行)選択モーダル
import React from 'react';
import PropTypes from 'prop-types';
import JapaneseAlphabeticalRows from '../../../../../../common/atoms/JapaneseAlphabeticalRows';

export default class DiseaseAlphabeticalRowSelectModal extends React.Component {
  prevModal () {
    if (this.props.previousModal === 'top') {
      // モーダルを全て閉じる
      this.props.setModal(null);
      this.props.setPreviousModal(null);
    } else {
      // 病名/診療科/症状/治療名モーダルへ戻る
      this.props.setModal('treatment');
    }
  }

  handleClick (setDiseaseAlphabeticalRowCode) {
    if (setDiseaseAlphabeticalRowCode === '99') {
      this.props.setModal('diseaseNameSelect');
      this.props.setDiseaseOtherSearchMode(true);
      this.props.fetchDiseases('その他');

      return;
    }

    this.props.setModal('diseaseAlphabeticalOrderSelect');
    this.props.setDiseaseAlphabeticalRow(setDiseaseAlphabeticalRowCode);
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">病名から探す</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {JapaneseAlphabeticalRows.all().map((japaneseAlphabeticalRow) => {
            return (
              <li className="c-mn-sel-list" key={japaneseAlphabeticalRow.code}>
                <a onClick={() => this.handleClick(japaneseAlphabeticalRow.code)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{japaneseAlphabeticalRow.name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

DiseaseAlphabeticalRowSelectModal.propTypes = {
  isActive: PropTypes.any,
  previousModal: PropTypes.string,
  setModal: PropTypes.func,
  setPreviousModal: PropTypes.func,
  setDiseaseAlphabeticalRow: PropTypes.func,
  setDiseaseOtherSearchMode: PropTypes.func,
  fetchDiseases: PropTypes.func
};
