import * as React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
// 医療機関のサンプル画像
import sampleInstitutionImagePath from '../../../../../images/front/pc/dummy/ranking01.png';

function CommonButton (className, onClick) {
  return (
    <button className={`slide-arrow ${className.includes('slick-prev') ? 'prev-arrow' : 'next-arrow'}${className.includes('slick-disabled') ? ' slick-disabled' : ''}`} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
        <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
      </svg>
    </button>
  );
}

function PrevButton (props) {
  const { className, onClick } = props;
  return CommonButton(className, onClick);
}

function NextButton (props) {
  const { className, onClick } = props;
  return CommonButton(className, onClick);
}

export default class DpcRankingSlick extends React.Component {
  constructor (props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next () {
    this.slider.slickNext();
  }

  previous () {
    this.slider.slickPrev();
  }

  institutionImageStyle (photos) {
    if (photos.length) {
      return { backgroundImage: `url(${photos[0].image_url})` };
    } else {
      return { backgroundImage: `url(${sampleInstitutionImagePath})` };
    }
  }

  processStationNames (stationName) {
    return stationName.includes('駅') ? stationName : `${stationName}駅`;
  }

  doctorCounselingLabel () {
    return (
      <div className="p-institution-search__free-charge">
        <div className="p-institution-search__free-charge-icon">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99999 13.1398L12.1125 15.0223C12.6825 15.3673 13.38 14.8573 13.23 14.2123L12.405 10.6723L15.1575 8.28732C15.66 7.85232 15.39 7.02732 14.73 6.97482L11.1075 6.66732L9.68999 3.32232C9.43499 2.71482 8.56499 2.71482 8.30999 3.32232L6.89249 6.65982L3.26999 6.96732C2.60999 7.01982 2.33999 7.84482 2.84249 8.27982L5.59499 10.6648L4.76999 14.2048C4.61999 14.8498 5.31749 15.3598 5.88749 15.0148L8.99999 13.1398Z" fill="#FFEB84"/>
          </svg>
        </div>
        <div className="p-institution-search__free-charge-txt">医師への無料相談可</div>
      </div>
    );
  }

  render () {
    const settings = {
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />
    };

    return (
      <>
        <div className="pg-institution-search-top__ranking-list">
          <Slider ref={c => (this.slider = c)} {...settings}>
            { this.props.institutionInfos.map((institutionInfo, idx) => {
              return (
                <div className="pg-institution-search-top__ranking-list-item" key={institutionInfo.institution.code}>
                  {/* FIXME: 医療機関カードのリンク先未設定のため要対応 */}
                  <a className="pg-institution-search-top__ranking-list-link" href="">
                    <div className="pg-institution-search-top__ranking-card">
                      <div className="pg-institution-search-top__ranking-card-img-wrap">
                        <div className="pg-institution-search-top__ranking-card-img" style={this.institutionImageStyle(institutionInfo.institution.photos)}>
                          <div className={`p-institution-search__ranking-number${idx <= 2 ? `0${idx + 1}` : ''}`}>{idx + 1}</div>
                          {institutionInfo.is_mail_counseling ? this.doctorCounselingLabel() : ''}
                        </div>
                      </div>
                      <div className="pg-institution-search-top__ranking-card-txt">
                        <div className="pg-institution-search-top__ranking-card-txt-count">治療実績数 {institutionInfo.achievement.toLocaleString()}件</div>
                        <div className="pg-institution-search-top__ranking-card-txt-name">
                          <p>{institutionInfo.institution.name}</p>
                        </div>
                        <div className="pg-institution-search-top__ranking-card-txt-address">
                          <svg className=" c-mn-icon-pin" width="15" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="c-mn-icon-pin-path" fillRule="evenodd" clipRule="evenodd" d="M7.15592 0C3.65789 0 0.489258 2.832 0.489258 6.336C0.489258 9.84 3.38024 14.008 7.15592 20C10.9316 14.008 13.8226 9.832 13.8226 6.336C13.8226 2.84 10.6547 0 7.15592 0ZM7.15672 9.16798C5.80971 9.14072 4.73663 8.00994 4.75378 6.63583C4.77094 5.26172 5.87189 4.15918 7.21915 4.16691C8.5664 4.17464 9.65512 5.28976 9.65711 6.66398C9.65131 7.33431 9.38457 7.97481 8.9156 8.44445C8.44663 8.91409 7.8139 9.17437 7.15672 9.16798Z" fill="#000"/>
                          </svg>
                          <span>{institutionInfo.institution.postal_address.prefecture_name} {institutionInfo.nearest_station_name_string}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
            <div className="pg-institution-search-top__ranking-list-item">
              {/* FIXME: 医療機関カードのリンク先未設定のため要対応 */}
              <a className="pg-institution-search-top__ranking-list-link--last" href="">
                <span>6位以降の病院を見る
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"/>
                  </svg>
                </span>
              </a>
            </div>
          </Slider>
        </div>
      </>
    );
  }
}

DpcRankingSlick.propTypes = {
  institutionInfos: PropTypes.array
};
