// [駅/路線] 都道府県選択モーダル
import React from 'react';
import PropTypes from 'prop-types';

export default class PrefectureSelectModal extends React.Component {
  constructor (props) {
    super(props);
    this.prefectures = {
      '01': [
        { id: '01', name: '北海道' },
        { id: '02', name: '青森県' },
        { id: '03', name: '岩手県' },
        { id: '04', name: '宮城県' },
        { id: '05', name: '秋田県' },
        { id: '06', name: '山形県' },
        { id: '07', name: '福島県' }
      ],
      '02': [
        { id: '08', name: '茨城県' },
        { id: '09', name: '栃木県' },
        { id: '10', name: '群馬県' },
        { id: '11', name: '埼玉県' },
        { id: '12', name: '千葉県' },
        { id: '13', name: '東京都' },
        { id: '14', name: '神奈川県' }
      ],
      '03': [
        { id: '15', name: '新潟県' },
        { id: '16', name: '富山県' },
        { id: '17', name: '石川県' },
        { id: '18', name: '福井県' },
        { id: '19', name: '山梨県' },
        { id: '20', name: '長野県' },
        { id: '21', name: '岐阜県' },
        { id: '22', name: '静岡県' },
        { id: '23', name: '愛知県' },
        { id: '24', name: '三重県' }
      ],
      '04': [
        { id: '25', name: '滋賀県' },
        { id: '26', name: '京都府' },
        { id: '27', name: '大阪府' },
        { id: '28', name: '兵庫県' },
        { id: '29', name: '奈良県' },
        { id: '30', name: '和歌山県' }
      ],
      '05': [
        { id: '31', name: '鳥取県' },
        { id: '32', name: '島根県' },
        { id: '33', name: '岡山県' },
        { id: '34', name: '広島県' },
        { id: '35', name: '山口県' },
        { id: '36', name: '徳島県' },
        { id: '37', name: '香川県' },
        { id: '38', name: '愛媛県' },
        { id: '39', name: '高知県' }
      ],
      '06': [
        { id: '40', name: '福岡県' },
        { id: '41', name: '佐賀県' },
        { id: '42', name: '長崎県' },
        { id: '43', name: '熊本県' },
        { id: '44', name: '大分県' },
        { id: '45', name: '宮崎県' },
        { id: '46', name: '鹿児島県' },
        { id: '47', name: '沖縄県' }
      ]
    };
  }

  prevModal () {
    this.props.setModal('stationAreaSelect');
  }

  handleClick (stationPrefectureCode) {
    this.props.setModal('lineSelect');
    this.props.setStationPrefectureCode(stationPrefectureCode);
    this.props.fetchLines(stationPrefectureCode);
  }

  render () {
    const prefectures = this.props.stationAreaCode ? this.prefectures[this.props.stationAreaCode] : [];

    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">駅/路線</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          {prefectures.map((prefecture) => {
            return (
              <li className="c-mn-sel-list" key={prefecture.id}>
                <a onClick={() => this.handleClick(prefecture.id)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{prefecture.name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

PrefectureSelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  setStationPrefectureCode: PropTypes.func,
  stationAreaCode: PropTypes.string,
  stationPrefectureCode: PropTypes.string,
  fetchLines: PropTypes.func
};
