import React from 'react';
import PropTypes from 'prop-types';
import BaseModals from './BaseModals';
import DiseaseAlphabeticalRowSelectModal from './treatment/DiseaseAlphabeticalRowSelectModal';
import DiseaseAlphabeticalOrderSelectModal from './treatment/DiseaseAlphabeticalOrderSelectModal';
import DiseaseNameSelectModal from './treatment/DiseaseNameSelectModal';

export default class SearchTopDiseaseModals extends BaseModals {
  render () {
    return (
      <div className="l-modal">
        <div className={this.props.modal ? 'p-institution-search__modal is-active' : 'p-institution-search__modal'}>
          {/* 病名 かな五十音順(行)選択モーダル */}
          <DiseaseAlphabeticalRowSelectModal
            isActive={this.props.modal === 'diseaseAlphabeticalRowSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            setPreviousModal={(modalName) => this.props.setPreviousModal(modalName)}
            setDiseaseAlphabeticalRow={(diseaseAlphabeticalRow) => this.setDiseaseAlphabeticalRow(diseaseAlphabeticalRow)}
          />
          {/* 病名 かな五十音順(あいうえお順)選択モーダル */}
          <DiseaseAlphabeticalOrderSelectModal
            isActive={this.props.modal === 'diseaseAlphabeticalOrderSelect'}
            diseaseAlphabeticalRow={this.state.diseaseAlphabeticalRow}
            setModal={(modalName) => this.props.setModal(modalName)}
            setDiseaseAlphabeticalOrder={(diseaseAlphabeticalOrder) => this.setDiseaseAlphabeticalOrder(diseaseAlphabeticalOrder)}
            fetchDiseases={this.fetchDiseases}
          />
          {/* かな五十音順(行)選択後の病名選択モーダル */}
          <DiseaseNameSelectModal
            isActive={this.props.modal === 'diseaseNameSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            diseases={this.state.diseases}
            clearDiseases={this.clearDiseases}
            setSearchTreatmentCondition={(searchTreatmentObject) => this.props.setSearchTreatmentCondition(searchTreatmentObject)}
            searchTreatmentCondition={this.props.searchTreatmentCondition}
            searchSelectConditionUrl={(key, value) => this.props.searchSelectConditionUrl(key, value)}
          />
        </div>
      </div>
    );
  }
}

SearchTopDiseaseModals.propTypes = {
  modal: PropTypes.any,
  setModal: PropTypes.func
};
