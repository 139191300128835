export default class Departments {
  // PO Selected
  static selection () {
    return [
      {
        code: '01',
        title: '内科',
        tags: [
          { code: '10', type: 'department', name: '感染症内科' },
          { code: '11', type: 'department', name: '血液内科' },
          { code: '12', type: 'department', name: '呼吸器内科' },
          { code: '13', type: 'department', name: '循環器内科' },
          { code: '14', type: 'department', name: '消化器内科' },
          { code: '16', type: 'department', name: '脳神経内科' },
          { code: '17', type: 'department', name: '腎臓内科' },
          { code: '18', type: 'department', name: '代謝内科' },
          { code: '19', type: 'department', name: '糖尿病内科' },
          { code: '20', type: 'department', name: '内科' },
          { code: '21', type: 'department', name: '内分泌内科' },
          { code: '24', type: 'department', name: 'アレルギー科' },
          { code: '25', type: 'department', name: 'リウマチ科' },
          { code: '37', type: 'department', name: '漢方内科' },
          { code: '38', type: 'department', name: '緩和ケア内科' },
          { code: '50', type: 'department', name: '神経内科' },
          { code: '51', type: 'department', name: '気管食道内科' },
          { code: '53', type: 'department', name: '性感染症内科' },
          { code: '55', type: 'department', name: '老年内科' },
          { code: '56', type: 'department', name: '腫瘍内科' },
          { code: '57', type: 'department', name: '内視鏡内科' },
          { code: '59', type: 'department', name: '人工透析内科' }
        ]
      },
      {
        code: '02',
        title: '外科',
        tags: [
          { code: '2', type: 'department', name: '呼吸器外科' },
          { code: '5', type: 'department', name: '消化器外科' },
          { code: '6', type: 'department', name: '心臓血管外科' },
          { code: '8', type: 'department', name: '乳腺外科' },
          { code: '36', type: 'department', name: '肛門外科' },
          { code: '49', type: 'department', name: '外科' },
          { code: '52', type: 'department', name: '胸部外科' },
          { code: '58', type: 'department', name: '胃腸外科' },
          { code: '66', type: 'department', name: '内視鏡外科' }
        ]
      },
      {
        code: '03',
        title: '脳神経外科',
        tags: [
          { code: '9', type: 'department', name: '脳神経外科' }
        ]
      },
      {
        code: '04',
        title: '小児科・小児外科',
        tags: [
          { code: '4', type: 'department', name: '小児外科' },
          { code: '30', type: 'department', name: '小児科' }
        ]
      },
      {
        code: '05',
        title: '眼科',
        tags: [
          { code: '26', type: 'department', name: '眼科' }
        ]
      },
      {
        code: '06',
        title: '耳鼻咽喉科',
        tags: [
          { code: '27', type: 'department', name: '耳鼻咽喉科' },
          { code: '64', type: 'department', name: '頭頸部外科' }
        ]
      },
      {
        code: '07',
        title: '皮膚科',
        tags: [
          { code: '33', type: 'department', name: '皮膚科' },
          { code: '39', type: 'department', name: '美容皮膚科' }
        ]
      },
      {
        code: '08',
        title: '産科・婦人科',
        tags: [
          { code: '22', type: 'department', name: '産科' },
          { code: '23', type: 'department', name: '婦人科' },
          { code: '54', type: 'department', name: '産婦人科' },
          { code: '60', type: 'department', name: '女性内科' }
        ]
      },
      {
        code: '09',
        title: '泌尿器科',
        tags: [
          { code: '32', type: 'department', name: '泌尿器科' }
        ]
      },
      {
        code: '10',
        title: '整形外科',
        tags: [
          { code: '7', type: 'department', name: '整形外科' }
        ]
      },
      {
        code: '11',
        title: '形成外科',
        tags: [
          { code: '1', type: 'department', name: '形成外科' },
          { code: '40', type: 'department', name: '美容外科' }
        ]
      },
      {
        code: '12',
        title: '精神科・心療内科',
        tags: [
          { code: '15', type: 'department', name: '心療内科' },
          { code: '31', type: 'department', name: '精神科' },
          { code: '65', type: 'department', name: '児童精神科' }
        ]
      },
      {
        code: '13',
        title: '総合診療科',
        tags: [
          { code: '41', type: 'department', name: '総合診療科' }
        ]
      },
      {
        code: '14',
        title: '歯科',
        tags: [
          { code: '3', type: 'department', name: '口腔外科' },
          { code: '29', type: 'department', name: '歯科' },
          { code: '42', type: 'department', name: '小児歯科' },
          { code: '43', type: 'department', name: '矯正歯科' }
        ]
      },
      {
        code: '15',
        title: '麻酔科',
        tags: [
          { code: '35', type: 'department', name: '麻酔科' },
          { code: '44', type: 'department', name: 'ペインクリニック内科' },
          { code: '61', type: 'department', name: 'ペインクリニック外科' }
        ]
      },
      {
        code: '16',
        title: '放射線科',
        tags: [
          { code: '34', type: 'department', name: '放射線科' },
          { code: '62', type: 'department', name: '放射線診断科' },
          { code: '63', type: 'department', name: '放射線治療科' }
        ]
      },
      {
        code: '17',
        title: 'リハビリテーション科',
        tags: [
          { code: '45', type: 'department', name: 'リハビリテーション科' }
        ]
      },
      {
        code: '18',
        title: '病理診断科',
        tags: [
          { code: '47', type: 'department', name: '病理診断科' }
        ]
      }
    ];
  }

  static selectedDivisionTags (code) {
    return Departments.selection().find(element => element.code === code).tags;
  }
}
