import React from 'react';
import PropTypes from 'prop-types';
import BaseModals from './BaseModals';
import ModalDetail from './ModalDetail';
import AreaModal from './area/AreaModal';
import AreaSelectModal from './area/AreaSelectModal';
import PrefectureSelectModal from './area/PrefectureSelectModal';
import CitySelectModal from './area/CitySelectModal';
import TreatmentModal from './treatment/TreatmentModal';
import DiseaseAlphabeticalRowSelectModal from './treatment/DiseaseAlphabeticalRowSelectModal';
import DiseaseAlphabeticalOrderSelectModal from './treatment/DiseaseAlphabeticalOrderSelectModal';
import DiseaseNameSelectModal from './treatment/DiseaseNameSelectModal';
import DepartmentDivisionSelectModal from './treatment/DepartmentDivisionSelectModal';
import DepartmentSelectModal from './treatment/DepartmentSelectModal';
import StationAreaSelectModal from './station/AreaSelectModal';
import StationPrefectureSelectModal from './station/PrefectureSelectModal';
import LineSelectModal from './station/LineSelectModal';
import StationSelectModal from './station/StationSelectModal';

export default class SearchModals extends BaseModals {
  render () {
    return (
      <div className="l-modal">
        <div className={this.props.modal ? 'p-institution-search__modal is-active' : 'p-institution-search__modal'}>
          <ModalDetail
            isActive={this.props.modal === 'detail'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setPreviousModal={(modalName) => this.props.setPreviousModal(modalName)}
            previousModal={this.props.previousModal}
            searchAreaCondition={this.props.searchAreaCondition}
            searchTreatmentCondition={this.props.searchTreatmentCondition}
            findSearchOptionCondition={(searchOptionConditionName) => this.props.findSearchOptionCondition(searchOptionConditionName)}
            setSearchOptionCondition={(isFreeMailConsultationCondition) => this.props.setSearchOptionCondition(isFreeMailConsultationCondition)}
            institutionNameOrDoctorNameCondition={this.props.institutionNameOrDoctorNameCondition}
            setInstitutionNameOrDoctorNameCondition={(institutionNameOrDoctorName) => this.props.setInstitutionNameOrDoctorNameCondition(institutionNameOrDoctorName)}
            deleteSearchOptionCondition={(searchOptionConditionType) => this.props.deleteSearchOptionCondition(searchOptionConditionType)}
            displaySearchTreatmentConditions={this.props.displaySearchTreatmentConditions}
            clearSearchCondition={this.props.clearSearchCondition}
            searchUrl={this.props.searchUrl}
          />
          {/* 病名/診療科/症状/治療名 選択モーダル */}
          <TreatmentModal
            isActive={this.props.modal === 'treatment'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            mixerHost={this.props.mixerHost}
            searchTreatmentCondition={this.props.searchTreatmentCondition}
            setSearchTreatmentCondition={(searchTreatmentObject) => this.props.setSearchTreatmentCondition(searchTreatmentObject)}
            deleteSearchTreatmentCondition={(searchTreatmentObject) => this.props.deleteSearchTreatmentCondition(searchTreatmentObject)}
          />
          {/* 病名 かな五十音順(行)選択モーダル */}
          <DiseaseAlphabeticalRowSelectModal
            isActive={this.props.modal === 'diseaseAlphabeticalRowSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setDiseaseAlphabeticalRow={(diseaseAlphabeticalRow) => this.setDiseaseAlphabeticalRow(diseaseAlphabeticalRow)}
            setDiseaseOtherSearchMode={(diseaseOtherSearchMode) => this.setDiseaseOtherSearchMode(diseaseOtherSearchMode)}
            fetchDiseases={this.fetchDiseases}
          />
          {/* 病名 かな五十音順(あいうえお順)選択モーダル */}
          <DiseaseAlphabeticalOrderSelectModal
            isActive={this.props.modal === 'diseaseAlphabeticalOrderSelect'}
            diseaseAlphabeticalRow={this.state.diseaseAlphabeticalRow}
            setModal={(modalName) => this.props.setModal(modalName)}
            setDiseaseAlphabeticalOrder={(diseaseAlphabeticalOrder) => this.setDiseaseAlphabeticalOrder(diseaseAlphabeticalOrder)}
            setDiseaseOtherSearchMode={(diseaseOtherSearchMode) => this.setDiseaseOtherSearchMode(diseaseOtherSearchMode)}
            fetchDiseases={this.fetchDiseases}
          />
          {/* かな五十音順(行)選択後の病名選択モーダル */}
          <DiseaseNameSelectModal
            isActive={this.props.modal === 'diseaseNameSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            diseases={this.state.diseases}
            clearDiseases={this.clearDiseases}
            setSearchTreatmentCondition={(searchTreatmentObject) => this.props.setSearchTreatmentCondition(searchTreatmentObject)}
            diseaseOtherSearchMode={this.state.diseaseOtherSearchMode}
          />
          {/* 診療科区分 選択モーダル */}
          <DepartmentDivisionSelectModal
            isActive={this.props.modal === 'departmentDivisionSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setDepartmentDivision={(departmentDivision) => this.setDepartmentDivision(departmentDivision)}
          />
          {/* 診療科 選択モーダル */}
          <DepartmentSelectModal
            isActive={this.props.modal === 'departmentSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            departmentDivision={this.state.departmentDivision}
            departmentCode={this.state.departmentCode}
            setSearchTreatmentCondition={(searchTreatmentObject) => this.props.setSearchTreatmentCondition(searchTreatmentObject)}
          />
          {/* エリア/駅 選択モーダル */}
          <AreaModal
            isActive={this.props.modal === 'area'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            mixerHost={this.props.mixerHost}
            searchAreaCondition={this.props.searchAreaCondition}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
          />
          {/* [エリア] エリア選択モーダル */}
          <AreaSelectModal
            isActive={this.props.modal === 'areaSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setAreaCode={(areaCode) => this.setAreaCode(areaCode)}
          />
          {/* [エリア] 都道府県選択モーダル */}
          <PrefectureSelectModal
            isActive={this.props.modal === 'prefectureSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            setPrefectureCode={(prefectureCode) => this.setPrefectureCode(prefectureCode)}
            setPrefectureName={(prefectureName) => this.setPrefectureName(prefectureName)}
            areaCode={this.state.areaCode}
            fetchCities={this.fetchCities}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
          />
          {/* [エリア] 市区町村選択モーダル */}
          <CitySelectModal
            isActive={this.props.modal === 'citySelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            previousModal={this.props.previousModal}
            prefectureCode={this.state.prefectureCode}
            prefectureName={this.state.prefectureName}
            cities={this.state.cities}
            clearCities={this.clearCities}
            setCityCode={(cityCode) => this.setCityCode(cityCode)}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
          />
          {/* [駅/路線] エリア選択モーダル */}
          <StationAreaSelectModal
            isActive={this.props.modal === 'stationAreaSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            setStationAreaCode={(stationAreaCode) => this.setStationAreaCode(stationAreaCode)}
          />
          {/* [駅/路線] 都道府県選択モーダル */}
          <StationPrefectureSelectModal
            isActive={this.props.modal === 'stationPrefectureSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            stationAreaCode={this.state.stationAreaCode}
            stationPrefectureCode={this.state.stationPrefectureCode}
            setStationPrefectureCode={(stationPrefectureCode) => this.setStationPrefectureCode(stationPrefectureCode)}
            fetchLines={this.fetchLines}
          />
          {/* [駅/路線] 路線選択モーダル */}
          <LineSelectModal
            isActive={this.props.modal === 'lineSelect'}
            setModal={(modalName) => this.props.setModal(modalName)}
            stationAreaCode={this.state.stationAreaCode}
            stationPrefectureCode={this.state.stationPrefectureCode}
            setLineCode={(lineCode) => this.setLineCode(lineCode)}
            setLineName={(lineName) => this.setLineName(lineName)}
            lines={this.state.lines}
            clearLines={this.clearLines}
            fetchStations={this.fetchStations}
            mixerHost={this.props.MixerHost}
          />
          {/* [駅/路線] 駅選択モーダル */}
          <StationSelectModal
            isActive={this.props.modal === 'stationSelect'}
            previousModal={this.props.previousModal}
            setModal={(modalName) => this.props.setModal(modalName)}
            stationPrefectureCode={this.state.stationPrefectureCode}
            lineCode={this.state.lineCode}
            lineName={this.state.lineName}
            stations={this.state.stations}
            clearStations={this.clearStations}
            mixerHost={this.props.MixerHost}
            setSearchAreaCondition={(searchAreaObject) => this.props.setSearchAreaCondition(searchAreaObject)}
          />
        </div>
      </div>
    );
  }
}

SearchModals.propTypes = {
  modal: PropTypes.any,
  setModal: PropTypes.func,
  setDiseaseAlphabeticalRow: PropTypes.func,
  setDepartmentDivision: PropTypes.func,
  setDepartmentCode: PropTypes.func,
  setAreaCode: PropTypes.func,
  setPrefectureCode: PropTypes.func,
  setCityCode: PropTypes.func,
  areaCode: PropTypes.string,
  setStationAreaCode: PropTypes.func,
  setStationPrefectureCode: PropTypes.func,
  setLineCode: PropTypes.func,
  setLineName: PropTypes.func,
  institutionNameOrDoctorNameCondition: PropTypes.string,
  setInstitutionNameOrDoctorNameCondition: PropTypes.func,
  stationAreaCode: PropTypes.string,
  stationPrefectureCode: PropTypes.string,
  departmentDivision: PropTypes.string,
  departmentCode: PropTypes.string,
  searchIsFreeMailConsultation: PropTypes.any
};
