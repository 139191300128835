import React from 'react';
import PropTypes from 'prop-types';
import Options from '../../../../common/atoms/hospitals/Options';

export default class SearchModal extends React.Component {
  render () {
    return (
      <div className="l-modal">
        <div className="p-institution-search__modal">
          <div className="c-mn-modal is-active">
            <div className="c-mn-modal-popup pc-only js-modal-detail is-active">
              <div className="c-mn-modal-popup__header">詳細条件</div>
              <div className="c-mn-modal-popup__contents-wrap">
                {Options.all().map(group => {
                  return (
                    <React.Fragment key={group.id}>
                      <p className="p-institution-search__modal-title">{group.name}</p>
                      <div className="c-mn-checkbox-btn--col2 p-fractal__checkbox-btn">
                        {group.items.map(item => {
                          return (
                            <div key={item.id} className="c-mn-checkbox-btn">
                              <input
                                id={item.id}
                                name={item.name}
                                value={item.value}
                                className="c-mn-checkbox-btn__checkbox"
                                type="checkbox"
                                checked={this.props.findOption(item.name)}
                                onChange={this.props.handleChangeOption}
                              />
                              <label className="c-mn-checkbox-btn__label" htmlFor={item.id}>
                                <svg width="29" height="20" viewBox="0 0 29 20" fill="none" className="c-mn-icon-check" xmlns="http://www.w3.org/2000/svg"><path d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000" className="c-mn-icon-check-path"></path><path d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000" className="c-mn-icon-check-path"></path></svg>
                                {item.value}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="p-institution-search__modal-footer">
                <a
                  className="c-mn-link"
                  href=""
                  onClick={this.props.handleClearOptions}
                >
                  条件をクリア
                </a>
                <a
                  className="c-mn-btn--first-s p-institution-search__modal-footer-btn"
                  href=""
                  onClick={this.props.handleSubmit}
                >
                  <span>この条件で検索する</span>
                </a>
              </div>
              <a
                className="c-mn-modal-popup__close js-modal-close"
                href=""
                onClick={this.props.handleCloseModal}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="c-mn-icon-close" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#fff" className="c-mn-icon-close-x"></circle><path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM16.151 17.943L12.008 13.841L7.891 18L6.058 16.167L10.162 12.01L6 7.891L7.833 6.058L11.988 10.16L16.094 6L17.943 7.849L13.843 11.99L18 16.094L16.151 17.943Z" fill="#000" className="c-mn-icon-close-circle"></path><circle cx="12" cy="12" r="11.5" stroke="#000" className="c-mn-icon-close-flame"></circle></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchModal.propTypes = {
  handleOpenModal: PropTypes.func,
  handleCloseModal: PropTypes.func,
  findOption: PropTypes.func,
  handleChangeOption: PropTypes.func,
  handleClearOptions: PropTypes.func,
  handleSubmit: PropTypes.func
};
