import React from 'react';
import PropTypes from 'prop-types';
import { DISEASES_PATH, SYMPTOMS_PATH, CHECKUPS_PATH, HOSPITALS_PATH, DOCTORS_PATH, FEATURES_PATH, NJ_ARTICLES_PATH, CANCER_PORTAL_PATH } from '../../../constants';

class NavMenu extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpenSearchBox: false,
      isDisplayDiseaseSymptomMenu: false,
      isDisplayHospitalDoctorMenu: false,
      isDisplayOtherMenu: false
    };
    this.onDiseaseSymptomNavMouseEnter = this.onDiseaseSymptomNavMouseEnter.bind(this);
    this.onDiseaseSymptomNavMouseLeave = this.onDiseaseSymptomNavMouseLeave.bind(this);
    this.onHospitalDoctorNavMouseEnter = this.onHospitalDoctorNavMouseEnter.bind(this);
    this.onHospitalDoctorNavMouseLeave = this.onHospitalDoctorNavMouseLeave.bind(this);
    this.onOtherNavMouseEnter = this.onOtherNavMouseEnter.bind(this);
    this.onOtherNavMouseLeave = this.onOtherNavMouseLeave.bind(this);
  }

  onDiseaseSymptomNavMouseEnter () {
    this.setState({ isDisplayDiseaseSymptomMenu: true });
  }

  onDiseaseSymptomNavMouseLeave () {
    this.setState({ isDisplayDiseaseSymptomMenu: false });
  }

  onHospitalDoctorNavMouseEnter () {
    this.setState({ isDisplayHospitalDoctorMenu: true });
  }

  onHospitalDoctorNavMouseLeave () {
    this.setState({ isDisplayHospitalDoctorMenu: false });
  }

  onOtherNavMouseEnter () {
    this.setState({ isDisplayOtherMenu: true });
  }

  onOtherNavMouseLeave () {
    this.setState({ isDisplayOtherMenu: false });
  }

  render () {
    return (
      <div className="p-lower-header__nav p-header-responsive__logo-area-nav">
        <ul itemScope itemType="http://www.schema.org/SiteNavigationElement">
          <li onMouseEnter={this.onDiseaseSymptomNavMouseEnter} onMouseLeave={this.onDiseaseSymptomNavMouseLeave}>
            <a href={DISEASES_PATH}>病気や症状を調べる</a>
            <div className={`p-lower-header__sub ${this.state.isDisplayDiseaseSymptomMenu ? 'is-show' : ''}`}>
              <ul className="p-lower-header__sub-list">
                <li itemProp="name" className="p-lower-header__sub-item">
                  <a itemProp="url" href={DISEASES_PATH}>病気を調べる</a>
                </li>
                <li itemProp="name" className="p-lower-header__sub-item">
                  <a itemProp="url" href={SYMPTOMS_PATH}>症状を調べる</a>
                </li>
                <li className="p-lower-header__sub-item">
                  <a href={CHECKUPS_PATH}>検査を調べる</a>
                </li>
              </ul>
            </div>
          </li>
          <li itemProp="name"><a itemProp="url" href={this.props.config.qaUrl}>医療相談</a></li>
          <li onMouseEnter={this.onHospitalDoctorNavMouseEnter} onMouseLeave={this.onHospitalDoctorNavMouseLeave}>
            <a href={HOSPITALS_PATH}>病院・医師を探す</a>
            <div className={`p-lower-header__sub ${this.state.isDisplayHospitalDoctorMenu ? 'is-show' : ''}`}>
              <ul className="p-lower-header__sub-list">
                <li itemProp="name" className="p-lower-header__sub-item">
                  <a itemProp="url" href={HOSPITALS_PATH}>病院を探す</a>
                </li>
                <li itemProp="name" className="p-lower-header__sub-item">
                  <a itemProp="url" href={DOCTORS_PATH}>医師を探す</a>
                </li>
              </ul>
            </div>
          </li>
          <li onMouseEnter={this.onOtherNavMouseEnter} onMouseLeave={this.onOtherNavMouseLeave}>
            <a href={FEATURES_PATH}>その他</a>
            <div className={`p-lower-header__sub ${this.state.isDisplayOtherMenu ? 'is-show' : ''}`}>
              <ul className="p-lower-header__sub-list">
                <li className="p-lower-header__sub-item">
                  <a href={FEATURES_PATH}>MNニュース</a>
                </li>
                <li itemProp="name" className="p-lower-header__sub-item">
                  <a itemProp="url" href={NJ_ARTICLES_PATH}>NEWS &amp; JOURNAL</a>
                </li>
                <li className="p-lower-header__sub-item">
                  <a href={CANCER_PORTAL_PATH}>がんポータル</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

NavMenu.propTypes = {
  config: PropTypes.object.isRequired
};

export default NavMenu;
