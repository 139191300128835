// 病名/診療科/症状/治療名モーダル
import * as React from 'react';
import PropTypes from 'prop-types';
import FetchPublicApiComponent from '../../../../../../common/atoms/FetchPublicApiComponent';
import TreatmentModalSelectedSuggestList from '../../../../atoms/TreatmentModalSelectedSuggestList';
import TreatmentModalSuggestList from '../../../../atoms/TreatmentModalSuggestList';
import TreatmentModalMenuList from '../../../../atoms/TreatmentModalMenuList';

export default class TreatmentModal extends FetchPublicApiComponent {
  constructor (props) {
    super(props);
    this.props.setModal(null);
    this.state = {
      // サジェスト入力欄のvalue値
      word: '',
      // サジェスト候補リスト
      suggests: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickNextButton = this.handleClickNextButton.bind(this);
    this.treatmentRef = React.createRef();
  }

  componentDidUpdate () {
    this.treatmentRef.current.focus();
  }

  closeModal () {
    if (this.props.previousModal) {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  // サジェストAPI
  fetchSuggestTreatments (word = '') {
    if (word.length < 1) {
      return this.setState({ suggests: [] });
    }
    const apiLocation = `${this.props.mixerHost}/suggest/treatments?word=${word}`;
    this
      .fetch(apiLocation)
      .then(data => {
        // 病名（疾患名称）での検索はできないので強制的に空にする。mixierで空を返すか迷ったがsiliconだけで完結させる
        data.data.diseases = [];

        const suggests = [];
        // 病名(疾患)の表示名加工
        data.data.diseases.forEach(disease => {
          suggests.push({ type: 'disease', code: disease.code, name: disease.name });
        });
        // 検査の表示名加工
        data.data.checkups.forEach(checkup => {
          suggests.push({ type: 'checkup', code: checkup.code, name: checkup.name });
        });
        // 治療名の表示名加工
        data.data.treatments.forEach(treatments => {
          suggests.push({ type: 'checkup', code: treatments.code, name: treatments.name });
        });
        // 診療科の表示名加工
        data.data.departments.forEach(department => {
          suggests.push({ type: 'department', code: department.code, name: department.name });
        });
        // サジェストリストデータの更新
        this.setState({ suggests: suggests });
      });
  }

  // サジェスト検索
  handleChange (event) {
    const word = event.target.value;
    this.setState({ word: word });
    this.fetchSuggestTreatments(word);
  }

  // サジェストリストを選択時の処理
  handleSelected (suggest) {
    // 選択済みの検索条件を保持
    this.props.setSearchTreatmentCondition(suggest);
    this.setState(state => {
      // サジェストリスト、検索ワードの初期化
      return { suggests: [], word: '' };
    });
  }

  // 次へボタンを押下時の処理
  handleClickNextButton () {
    if (this.props.previousModal) {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  // サジェストから選択した検索条件の削除
  handleDeleteSelected (suggest) {
    this.props.deleteSearchTreatmentCondition(suggest);
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-inner js-modal-diseases is-active' : 'p-institution-search__modal-inner js-modal-diseases'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.closeModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          {/* <p className="p-institution-search__modal-header-title">病名/診療科/症状/治療名</p> */}
          <p className="p-institution-search__modal-header-title">診療科/症状/治療名</p>
          <hr className="c-mn-line-second" />
        </div>
        {/* サジェスト検索要素 */}
        <div className="p-institution-search__modal-search-wrap">
          <div className="p-institution-search__modal-search">
            { this.props.searchTreatmentCondition.length !== 0
              ? <TreatmentModalSelectedSuggestList
                searchTreatmentCondition={this.props.searchTreatmentCondition}
                handleDeleteSelected={(suggest) => this.handleDeleteSelected(suggest)}
              />
              : ''
            }
            {/* サジェストの入力 */}
            {/* <input className="c-mn-input-text" type="text" ref={this.treatmentRef} placeholder={this.props.searchTreatmentCondition.length === 0 ? '病名/診療科等を入力' : ''} onChange={this.handleChange} value={this.state.word} /> */}
            <input className="c-mn-input-text" type="text" ref={this.treatmentRef} placeholder={this.props.searchTreatmentCondition.length === 0 ? '診療科等を入力' : ''} onChange={this.handleChange} value={this.state.word} />
          </div>
          <button className="p-institution-search__modal-search-btn" onClick={this.handleClickNextButton} disabled={this.props.searchTreatmentCondition.length === 0}>次へ</button>
          <hr className="c-mn-line-second" />
        </div>
        {/* リスト表示エリア(サジェスト候補とメニューの出し分け) */}
        { this.state.suggests.length > 0
          ? <TreatmentModalSuggestList
            setModal={this.props.setModal}
            suggests={this.state.suggests}
            handleSelected={(suggest) => this.handleSelected(suggest)}
          />
          : <TreatmentModalMenuList setModal={this.props.setModal} />
        }
      </div>
    );
  }
}

TreatmentModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func
};
