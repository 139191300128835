import React from 'react';
import RecentHospitalComponent from '../../../../common/organisms/RecentHospitalComponent';

export default class RecentHospitalSearch extends RecentHospitalComponent {
  componentDidMount () {
    this.fetchRecentHospitals();
  }

  render () {
    return (
      <>
        { this.state.recentHospitals.length
          ? <>
            <div className="p-institution-search__hospital-title">
              <h3 className="c-mn-title-3 pc-only">最近見た病院</h3>
              <hr className="c-mn-line-second" />
            </div>
            { this.state.recentHospitals.slice(0, this.state.viewMoreRecentHospital ? 10 : 3).map(hospital => {
              return (
                <div className="p-institution-search__hospital-block" key={hospital.code}>
                  <a className="p-institution-search__hospital-block-name" href={`/hospitals/${hospital.code}`}>{ hospital.name }
                    <svg className="c-mn-icon-arrow2" width="15" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path className="c-mn-icon-arrow2-circle" fillRule="evenodd" clipRule="evenodd" d="M0.836914 10C0.836914 4.5 5.33691 0 10.8369 0C16.3369 0 20.8369 4.5 20.8369 10C20.8369 15.5 16.3369 20 10.8369 20C5.33691 20 0.836914 15.5 0.836914 10ZM8.27266 5.99282C7.88214 5.6023 7.88214 4.96913 8.27266 4.57861C8.66319 4.18808 9.29635 4.18808 9.68688 4.57861L14.3336 9.22531C14.3577 9.2494 14.3803 9.27442 14.4014 9.30025C14.4272 9.32136 14.4522 9.34396 14.4763 9.36805C14.8668 9.75858 14.8668 10.3917 14.4763 10.7823L9.82991 15.4287C9.43939 15.8192 8.80622 15.8192 8.4157 15.4287C8.02517 15.0381 8.02517 14.405 8.4157 14.0145L12.355 10.0752L8.27266 5.99282Z" fill="#000"></path>
                    </svg>
                  </a>
                  <div className="p-institution-search__hospital-block-wrap">
                    <div className="p-institution-search__hospital-block-station">
                      <svg className="c-mn-icon-pin" width="15" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="c-mn-icon-pin-path" fillRule="evenodd" clipRule="evenodd" d="M7.15592 0C3.65789 0 0.489258 2.832 0.489258 6.336C0.489258 9.84 3.38024 14.008 7.15592 20C10.9316 14.008 13.8226 9.832 13.8226 6.336C13.8226 2.84 10.6547 0 7.15592 0ZM7.15672 9.16798C5.80971 9.14072 4.73663 8.00994 4.75378 6.63583C4.77094 5.26172 5.87189 4.15918 7.21915 4.16691C8.5664 4.17464 9.65512 5.28976 9.65711 6.66398C9.65131 7.33431 9.38457 7.97481 8.9156 8.44445C8.44663 8.91409 7.8139 9.17437 7.15672 9.16798Z" fill="#000"></path>
                      </svg>
                      <strong>{ hospital.prefecture }</strong>{ hospital.station }
                    </div>
                    <div className="p-institution-search__hospital-block-open">
                      <svg className="c-mn-icon-time" width="15" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.366 0C4.84598 0 0.375977 4.48 0.375977 10C0.375977 15.52 4.84598 20 10.366 20C15.896 20 20.376 15.52 20.376 10C20.376 4.48 15.896 0 10.366 0ZM10.376 18C5.95598 18 2.37598 14.42 2.37598 10C2.37598 5.58 5.95598 2 10.376 2C14.796 2 18.376 5.58 18.376 10C18.376 14.42 14.796 18 10.376 18Z" fill="#000"></path>
                        <path className="c-mn-icon-time-path" d="M10.876 5H9.37598V11L14.626 14.15L15.376 12.92L10.876 10.25V5Z" fill="#000"></path>
                      </svg>
                      { hospital.timetable }
                    </div>
                  </div>
                  <hr className="c-mn-line-second"></hr>
                </div>
              );
            })}
            { !this.state.viewMoreRecentHospital && this.state.recentHospitals.length > 3
              ? (
                <div className="p-institution-search__hospital-archive"><button className="c-mn-btn--third-s" onClick={ () => { this.setState({ viewMoreRecentHospital: true }); } }><span>すべての閲覧履歴を見る</span></button></div>
              )
              : null }
          </>
          : null }
      </>
    );
  }
}
