// 都道府県選択後の市区町村選択モーダル
import React from 'react';
import PropTypes from 'prop-types';

export default class CitySelectModal extends React.Component {
  prevModal () {
    this.props.setModal('prefectureSelect');
    this.props.clearCities();
  }

  handleClick (city) {
    // エリア/駅の検索条件をセットする
    // { type: 'city', code: '市区町村コード', name: '市区町村名(県名)' }
    const condition = { type: 'city', code: city.city_code, name: `${city.city_name}(${this.props.prefectureName})` };
    this.props.setSearchAreaCondition(condition);

    if (this.props.previousModal === 'detail') {
      // previousModalの設定値が存在する場合は設定値のモーダルを開く
      // (詳細条件モーダルからエリアモーダルを開いた場合を想定)
      this.props.setModal(this.props.previousModal);
    } else if (this.props.previousModal === 'areaSearchForm') {
      // 治療支援検索TOP下部のエリア/駅から探すから遷移してきた場合は選択した市区町村で検索結果画面へ遷移する。
      location.href = this.props.searchSelectConditionUrl('area', condition);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  handleClickPrefecture () {
    // 都道府県を検索条件をセットする
    // { type: 'prefecture', code: '都道府県コード', name: '都道府県名' }
    const condition = { type: 'prefecture', code: this.props.prefectureCode, name: this.props.prefectureName };
    this.props.setSearchAreaCondition(condition);

    if (this.props.previousModal === 'detail') {
      // 詳細条件モーダルからエリアモーダルを開いた場合
      this.props.setModal(this.props.previousModal);
    } else if (this.props.previousModal === 'areaSearchForm') {
      // 治療支援検索TOP下部のエリア/駅から探すから遷移してきた場合は選択した都道府県で検索結果画面へ遷移する。
      location.href = this.props.searchSelectConditionUrl('area', condition);
    } else {
      // エリア/駅モーダルを閉じる
      this.props.setModal(null);
    }
  }

  render () {
    return (
      <div className={ this.props.isActive ? 'p-institution-search__modal-area js-modal-area is-active' : 'p-institution-search__modal-area js-modal-area'}>
        <div className="p-institution-search__modal-header">
          <a className="p-institution-search__modal-header-link js-modal-close" onClick={() => { this.prevModal(); }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" width="15" className="c-mn-icon-arrow">
              <path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-mn-icon-arrow-path"/>
            </svg>
            戻る
          </a>
          <p className="p-institution-search__modal-header-title">市区町村</p>
          <hr className="c-mn-line-second" />
        </div>
        <ul className="c-mn-sel-list-group">
          <li className="c-mn-sel-list" key={this.props.prefectureCode}>
            <a onClick={() => this.handleClickPrefecture()}>
              <div className="c-mn-sel-txt">
                <p className="c-mn-sel-title">{this.props.prefectureName}</p>
              </div>
              <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
              </svg>
            </a>
          </li>
          {this.props.cities.map((city) => {
            return (
              <li className="c-mn-sel-list" key={city.city_code}>
                <a onClick={() => this.handleClick(city)}>
                  <div className="c-mn-sel-txt">
                    <p className="c-mn-sel-title">{city.city_name}</p>
                  </div>
                  <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
                    <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
                  </svg>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

CitySelectModal.propTypes = {
  isActive: PropTypes.any,
  setModal: PropTypes.func,
  previousModal: PropTypes.string,
  setCityCode: PropTypes.func,
  setSearchAreaCondition: PropTypes.func,
  searchSelectConditionUrl: PropTypes.func,
  prefectureCode: PropTypes.string,
  prefectureName: PropTypes.string,
  clearCities: PropTypes.func,
  cities: PropTypes.array
};
