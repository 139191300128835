import React from 'react';
import {
  AbstractComponent,
  Areas,
  Prefectures,
  Spinner
} from '../../../../common/atoms/index';
import IconClose from '../../atoms/IconClose';

export default class AreaNav extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      breadcrumbs: this.initialBreadcrumbs(),
      list: this.fetchAreas(),
      loading: false,
      balloon: []
    };
    this.getCurrentArea = this.getCurrentArea.bind(this);
    this.handleClickCloseBalloon = this.handleClickCloseBalloon.bind(this);
  }

  componentDidMount () {
    if (this.props.params.prefecture_code) {
      this.setState(state => {
        const area = Areas.findByCode(Prefectures.findAreaCodeByPrefectureCode(this.props.params.prefecture_code));
        const prefecture = Prefectures.findByCode(this.props.params.prefecture_code);
        this.pushBreadcrumb(state.breadcrumbs, area);
        return {
          breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, prefecture),
          list: [
            { type: 'select_prefecture', name: `${prefecture.name}を選択`, code: prefecture.code },
            { type: 'select_city', name: '市区町村を選択', code: prefecture.code },
            { type: 'select_station', name: '駅を選択', code: prefecture.code }
          ]
        };
      });
    } else if (this.props.params.area_code) {
      this.setState(state => {
        const areas = this.fetchAreas();
        const area = areas.find(item => item.code === this.props.params.area_code);
        return {
          breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, area),
          list: this.fetchPrefectures(area.code)
        };
      });
    }
  }

  initialBreadcrumbs () {
    return [{ type: 'all', name: '全国' }];
  }

  fetchAreas () {
    return Areas.all().map(area => {
      area.type = area.code === 'current_area' ? 'select_area' : 'area';
      return area;
    });
  }

  getCurrentArea () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setCurrentArea, this.errorCurrentArea);
    } else {
      // 現在位置を取得できない場合の処理
      this.errorCurrentArea();
    }
  }

  setCurrentArea = (position) => {
    const data = position.coords;
    const lat = data.latitude;
    const lng = data.longitude;
    window.location.href = `/hospitals/search?current_area=true&latitude=${lat}&longitude=${lng}&area_code=current_area`;
  }

  errorCurrentArea () {
    alert('現在位置を取得できません');
  }

  fetchPrefectures (areaCode) {
    if (areaCode === 'current_area') {
      return [{ code: 'current_area', name: '現在地周辺', type: 'select_area' }];
    }

    return Prefectures.findByAreaCode(areaCode).map(prefecture => {
      prefecture.type = /^A/.test(prefecture.code) || prefecture.type === 'select_area' ? 'select_area' : 'prefecture';
      prefecture.code = /^A/.test(prefecture.code) ? prefecture.code.replace('A', '0') : prefecture.code;
      return prefecture;
    });
  }

  fetchCities (prefectureCode) {
    this.setState({ balloon: [] });

    const apiLocation = `${this.props.mixerHost}/cities?prefecture_code=${prefectureCode}`;
    this
      .fetch(apiLocation)
      .then(data => {
        const balloon = [];
        const object = {};
        data.data.forEach(data => {
          const initial = this.kanaToHira(data.city_name_kana.slice(0, 1));
          const city = {
            type: 'city',
            code: data.city_code,
            name: data.city_name
          };
          if (!object[initial]) {
            object[initial] = [];
          }
          object[initial].push(city);
        });
        for (const [initial, cities] of Object.entries(object)) {
          balloon.push({ title: initial, tags: cities });
        }
        balloon.sort((first, second) => {
          if (first.title < second.title) {
            return -1;
          }
          if (first.title > second.title) {
            return 1;
          }
          return 0;
        });

        this.setState({ balloon: balloon });
      });
  }

  fetchStations (prefectureCode) {
    this.setState({ loading: true, balloon: [] });

    const apiLocation = `${this.props.mixerHost}/station_lines?prefecture_code=${prefectureCode}`;
    this
      .fetch(apiLocation)
      .then(data => {
        const promises = data.data.map(line => {
          return new Promise((resolve) => {
            const apiLocationStation = `${this.props.mixerHost}/station_lines?prefecture_code=${prefectureCode}&line_code=${line.line_code}`;
            this
              .fetch(apiLocationStation)
              .then(data => {
                resolve({
                  title: line.line_name,
                  tags: data.data[0].station_masters.map(station => {
                    return {
                      type: 'station',
                      code: station.station_code,
                      name: station.station_name
                    };
                  })
                });
              });
          });
        });
        Promise
          .all(promises)
          .then(lines => {
            this.setState({ loading: false, balloon: lines });
          });
      });
  }

  kanaToHira (str) {
    return str.replace(/[\u30a1-\u30f6]/g, function (match) {
      const chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }

  handleClickList (event, item) {
    event.preventDefault();
    switch (item.type) {
    case 'area':
      this.setState(state => {
        return { breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, item), list: this.fetchPrefectures(item.code) };
      });
      break;
    case 'prefecture':
      this.setState(state => {
        return {
          breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, item),
          list: [
            { type: 'select_prefecture', name: `${item.name}を選択`, code: item.code },
            { type: 'select_city', name: '市区町村を選択', code: item.code },
            { type: 'select_station', name: '駅を選択', code: item.code }
          ]
        };
      });
      break;
    case 'select_area':
      if (item.code === 'current_area') {
        this.getCurrentArea();
        return false;
      }
      location.href = this.props.searchUrl({ area: { type: 'area', code: item.code } });
      break;
    case 'select_prefecture':
      location.href = this.props.searchUrl({ area: { type: 'prefecture', code: item.code } });
      break;
    case 'select_city':
      this.fetchCities(item.code);
      break;
    case 'select_station':
      this.fetchStations(item.code);
      break;
    }
  }

  handleClickBreadcrumb (event, breadcrumb) {
    event.preventDefault();
    this.setState(state => {
      const breadcrumbs = this.initialBreadcrumbs();
      let list = state.list;

      switch (breadcrumb.type) {
      case 'all':
        list = this.fetchAreas();
        break;
      case 'area':
        breadcrumbs.push(breadcrumb);
        list = this.fetchPrefectures(breadcrumb.code);
        break;
      }
      return { breadcrumbs: breadcrumbs, list: list };
    });
  }

  handleClickCloseBalloon () {
    this.setState({ balloon: [] });
  }

  pushBreadcrumb (breadcrumbs, item) {
    // 既に都道府県がある場合置き換え
    if (item.type === 'prefecture' && breadcrumbs.some(breadcrumb => breadcrumb.type === 'prefecture')) {
      const index = breadcrumbs.findIndex(breadcrumb => breadcrumb.type === 'prefecture');
      breadcrumbs.splice(index, 1, item);
      return breadcrumbs;
    }

    // 既に同じtypeがある場合何もしない
    if (breadcrumbs.some(breadcrumb => breadcrumb.type === item.type)) {
      return breadcrumbs;
    }

    // breadcrumbsにitemを追加
    breadcrumbs.push(item);
    return breadcrumbs;
  }

  renderBreadcrumbs () {
    if (this.state.breadcrumbs.size === 1) {
      return <li className="c-mn-breadcrumb__item"><a className="c-mn-breadcrumb__link" href="">全国</a></li>;
    }

    return (
      <ul className="c-mn-breadcrumb">
        {this.state.breadcrumbs.map((item, index) => {
          return (
            <li key={index} className="c-mn-breadcrumb__item">
              <a
                className="c-mn-breadcrumb__link"
                href=""
                onClick={event => this.handleClickBreadcrumb(event, item)}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  renderAreaList () {
    try {
      return (
        <ul
          className={this.state.list.every(item => item.type.includes('select')) ? 'p-institution-search__nav-verticallist' : 'p-institution-search__nav-list'}
        >
          {this.state.list.map((item, index) => {
            return (
              <li key={index} className={this.state.list.every(item => item.type.includes('select')) ? 'p-institution-search__nav-verticallist-item' : 'p-institution-search__nav-list-item'}>
                <a
                  className={item.type.includes('select') ? 'p-institution-search__nav-verticallist-link' : 'p-institution-search__nav-list-link'}
                  href=""
                  onClick={event => this.handleClickList(event, item)}
                >
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      );
    } catch (error) {
      return null;
    }
  }

  render () {
    return (
      <>
        <p className="p-institution-search__nav-title">エリア/駅</p>
        <div className="p-institution-search__nav-contents">
          <div className="p-institution-search__nav-wrap js-nav-area">
            {this.renderBreadcrumbs()}
            {this.renderAreaList()}
          </div>
          <div className="p-institution-search__nav-balloon-wrap" style={{ display: this.state.loading || this.state.balloon.length > 0 ? 'block' : 'none' }}>
            <div
              style={{
                position: 'absolute',
                top: 8,
                right: 18,
                cursor: 'pointer',
                zIndex: 3
              }}
            >
              <IconClose handleClick={this.handleClickCloseBalloon}/>
            </div>
            <div className="p-institution-search__nav-balloon">
              {this.state.loading === true
                ? <div style={{ display: 'flex', justifyContent: 'center' }}><Spinner/></div>
                : null }
              {this.state.balloon.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className="p-institution-search__nav-balloon-title">{item.title}</p>
                    <ul className="c-mn-tag-list">
                      {item.tags.map(tag => {
                        return (
                          <li key={tag.code}>
                            <a
                              className="c-mn-tag-link"
                              href=""
                              onClick={event => {
                                event.preventDefault();
                                location.href = this.props.searchUrl({ area: tag });
                              }}
                            >
                              {tag.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
