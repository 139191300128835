import React from 'react';
import {
  AbstractComponent,
  Departments,
  JapaneseAlphabeticalOrders,
  JapaneseAlphabeticalRows
} from '../../../../common/atoms/index';
import IconClose from '../../atoms/IconClose';
import HospitalSearchMessage from '../../../../common/atoms/HospitalSearchMessage';

export default class TypeNav extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      breadcrumbs: this.initialBreadcrumbs(),
      list: this.initialList(),
      balloon: []
    };
    this.handleClickCloseBalloon = this.handleClickCloseBalloon.bind(this);
  }

  initialBreadcrumbs () {
    return [{ type: 'all', name: 'すべて' }];
  }

  initialList () {
    // return [
    //   { type: 'diseases', name: '病名から探す' },
    //   { type: 'departments', name: '診療科から探す' }
    // ];
    return [
      { type: 'departments', name: '診療科から探す' }
    ];
  }

  fetchJapaneseAlphabeticalRows () {
    return JapaneseAlphabeticalRows.all().map(row => {
      row.type = 'jp-row';
      return row;
    });
  }

  fetchJapaneseAlphabeticalOrders (code) {
    return JapaneseAlphabeticalOrders.findByCode(code).map(order => {
      order.type = 'jp-order';
      return order;
    });
  }

  fetchDiseases (initial) {
    let apiLocation = `${this.props.mixerHost}/tags?name[]=${initial}&name_condition=prefix&kind[]=1&page=1&limit=1000&end_user_visible=true`;
    if (initial === 'その他') {
      apiLocation = `${this.props.mixerHost}/tags?kind[]=1&page=1&limit=1000&end_user_visible=true&not_start_with_kana=true`;
    }

    this
      .fetch(apiLocation)
      .then(data => {
        if (data.data.length === 0) {
          this.setState({ balloon: [] });
          return;
        }

        this.setState({
          balloon: [
            {
              title: initial,
              tags: data.data.map(item => {
                return {
                  code: item.code,
                  type: 'tag',
                  name: item.name
                };
              })
            }
          ]
        });
      });
  }

  fetchTag (code) {
    // TODO: code
  }

  handleClickList (event, item) {
    event.preventDefault();
    switch (item.type) {
    case 'diseases':
      this.setState(state => {
        return {
          breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, { type: 'diseases', name: '病名' }),
          list: this.fetchJapaneseAlphabeticalRows(),
          balloon: []
        };
      });
      break;
    case 'departments':
      this.setState(state => {
        return { balloon: Departments.selection() };
      });
      break;
    case 'jp-row':
      if (item.code === '99') {
        this.fetchDiseases(item.name);
        return;
      }

      this.setState(state => {
        return {
          breadcrumbs: this.pushBreadcrumb(state.breadcrumbs, item),
          list: this.fetchJapaneseAlphabeticalOrders(item.code),
          balloon: []
        };
      });
      break;
    case 'jp-order':
      this.fetchDiseases(item.name);
      break;
    }
  }

  handleClickBreadcrumb (event, breadcrumb) {
    event.preventDefault();
    this.setState(state => {
      const breadcrumbs = this.initialBreadcrumbs();
      let list = state.list;

      switch (breadcrumb.type) {
      case 'all':
        list = this.initialList();
        break;
      case 'diseases':
        breadcrumbs.push(breadcrumb);
        list = this.fetchJapaneseAlphabeticalRows();
        break;
      }
      return { breadcrumbs: breadcrumbs, list: list, balloon: [] };
    });
  }

  handleClickCloseBalloon () {
    this.setState({ balloon: [] });
  }

  handleClickTagAndDepartmentItem (event, item) {
    event.preventDefault();
    if (!this.props.canTreatmentsSearch()) {
      this.setState(state => {
        return {
          balloon: []
        };
      });
      alert(HospitalSearchMessage.TreatmentsMaxCountAlert);
      return;
    }
    location.href = this.props.searchUrl({ treatment: item });
  }

  pushBreadcrumb (breadcrumbs, item) {
    // breadcrumbsにitemを追加
    breadcrumbs.push(item);
    return breadcrumbs;
  }

  renderBreadcrumbs () {
    if (this.state.breadcrumbs.size === 1) {
      return <ul className="c-mn-breadcrumb">
        <li className="c-mn-breadcrumb__item"><a className="c-mn-breadcrumb__link" href="">すべて</a></li>
      </ul>;
    }

    return (
      <ul className="c-mn-breadcrumb">
        {this.state.breadcrumbs.map((item, index) => {
          return (
            <li key={index} className="c-mn-breadcrumb__item">
              <a
                className="c-mn-breadcrumb__link"
                href=""
                onClick={event => this.handleClickBreadcrumb(event, item)}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  render () {
    return (
      <>
        {/* <p className="p-institution-search__nav-title">病名/診療科/検査名/治療名</p> */}
        <p className="p-institution-search__nav-title">診療科/検査名/治療名</p>
        <div className="p-institution-search__nav-contents">
          <div className="p-institution-search__nav-wrap js-nav-type">
            {this.renderBreadcrumbs()}
            <ul
              className={this.state.list.every(item => item.type.includes('select')) ? 'p-institution-search__nav-verticallist' : 'p-institution-search__nav-list'}
            >
              {this.state.list.map((item, index) => {
                return (
                  <li key={index} className={this.state.list.every(item => item.type.includes('select')) ? 'p-institution-search__nav-verticallist-item' : 'p-institution-search__nav-list-item'}>
                    <a
                      className={item.type.includes('select') ? 'p-institution-search__nav-verticallist-link' : 'p-institution-search__nav-list-link'}
                      href=""
                      onClick={event => this.handleClickList(event, item)}
                    >
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="p-institution-search__nav-balloon-wrap" style={{ display: this.state.balloon.length > 0 ? 'block' : 'none' }}>
            <div
              style={{
                position: 'absolute',
                top: 8,
                right: 12,
                cursor: 'pointer',
                zIndex: 3
              }}
            >
              <IconClose handleClick={this.handleClickCloseBalloon}/>
            </div>
            <div className="p-institution-search__nav-balloon">
              {this.state.balloon.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <p className="p-institution-search__nav-balloon-title">{item.title}</p>
                    <ul className="c-mn-tag-list">
                      {item.tags.map(tag => {
                        return (
                          <li key={tag.code}>
                            <a
                              className="c-mn-tag-link"
                              href=""
                              onClick={event => this.handleClickTagAndDepartmentItem(event, tag)}
                            >
                              {tag.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
