import React from 'react';
import PropTypes from 'prop-types';
import closeIcon from '../../../../../images/front/hospitals_search/icon/close';
import closeIcon02 from '../../../../../images/front/hospitals_search/icon/close02';
import FetchPublicApiComponent from '../../../../common/atoms/FetchPublicApiComponent';
import { JapaneseSyllabaryLines } from '../../atoms/hospitals/index';
import ModalItem from './ModalItem';
import styles from './Modal.module.scss';

class ModalDiseases extends FetchPublicApiComponent {
  constructor (props) {
    super(props);
    this.state = {
      word: '',
      allDiseases: {},
      loading: true,
      isActiveResult0: false,
      isActiveKa: true,
      isActiveDiseasesOther: true
    };
    this.inputWord = this.inputWord.bind(this);
    this.fetchAllDiseases = this.fetchAllDiseases.bind(this);
    this.extractSuggestDepartment = this.extractSuggestDepartment.bind(this);
  }

  componentDidMount () {
    this.fetchAllDiseases();
  }

  fetchAllDiseases () {
    const apiLocation = '/public/api/diseases';
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState(
          {
            allDiseases: data.diseases,
            loading: false
          }
        );
      });
  }

  inputWord (event) {
    const word = event.target.value;
    this.setState({ word: word });
  }

  // 診療科目を絞り込む
  extractSuggestDepartment (word) {
    if (word === undefined || word.length === 0) {
      return this.props.departmentSection;
    }

    const departmentData = {};
    Object.entries(this.props.departmentSection).forEach((department) => {
      const [name, items] = department;
      const filteredItems = Object.entries(items).filter(item => item[1].includes(word));
      if (filteredItems.length > 0) {
        departmentData[name] = Object.fromEntries(filteredItems);
      }
    });

    return departmentData;
  }

  canDisplaySuggestDiseases () {
    return this.state.loading;
  }

  extractSuggestDiseases (word) {
    const suggestDiseases = this.state.allDiseases.filter((disease) => {
      return disease.kana.includes(word) || disease.name.includes(word);
    });
    return suggestDiseases;
  }

  groupDiseasesByLine (diseases) {
    const groupedDiseasesByLine = JapaneseSyllabaryLines.all().map(line => {
      const matchedDiseases = diseases.filter((disease) => {
        return line.regex.test(disease.kana.substr(0, 1));
      });
      return {
        lineName: line.lineName,
        diseases: matchedDiseases
      };
    }).filter(group => group.diseases.length > 0);
    return groupedDiseasesByLine;
  }

  renderSuggestDiseases () {
    const diseases = this.state.word === '' ? this.state.allDiseases : this.extractSuggestDiseases(this.state.word);
    if (diseases.length === 0) { return this.renderSuggestResult0(); }

    return (
      <>
        {this.groupDiseasesByLine(diseases).map(groupedDiseases => {
          return (
            <React.Fragment key={groupedDiseases.lineName}>
              <p className="p-hospitals-search__modal-contents-subtitle js-search-ka" style={{ display: 'block' }}>{groupedDiseases.lineName}</p>
              <ul className={`p-hospitals-search__modal-contents-list js-search-ka ${styles.modalListFlexbox}`}>
                { groupedDiseases.diseases.map(disease => {
                  return (
                    <li key={`${disease.name}${disease.code}`} className="p-hospitals-search__modal-contents-list-item  js-search-ka" style={{ display: 'block' }}>
                      <a
                        className="p-hospitals-search__modal-contents-list-link js-diseases-input"
                        onClick={() => this.props.handleSelectDisease(disease)}
                      >
                        {disease.name}
                        <span>
                          {disease.kana}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
      </>
    );
  }

  renderSuggestResult0 () {
    return (
      <p className="p-hospitals-search__modal-contents-result0" style={{ display: 'block' }}>
        検索結果はありません
      </p>
    );
  }

  renderSearching () {
    return (
      <p className="p-hospitals-search__modal-contents-searching" style={{ display: 'block' }}>
        検索中です...
      </p>
    );
  }

  render () {
    const {
      isActive,
      closeModal
    } = this.props;

    const style = {
      display: isActive ? 'block' : 'none'
    };

    const departmentData = this.extractSuggestDepartment(this.state.word);

    return (
      <div className="p-hospitals-search__modal-diseases" style={style}>
        <div className="p-hospitals-search__modal-diseases-header">
          <img src={this.props.config.assetHost + closeIcon} width="24" className="p-hospitals-search__modal-area-header-icon-close js-modal-area-close" onClick={closeModal} alt="" />
          <img src={this.props.config.assetHost + closeIcon02} width="32" className="p-hospitals-search__modal-area-header-icon-close-pc js-modal-area-close" onClick={closeModal} alt="" />
          <div className="p-hospitals-search__modal-diseases-header-title-wrap">
            <span className="p-hospitals-search__modal-diseases-header-title">病名 / 診療科目を選択</span>
          </div>
          <div className="p-hospitals-search__modal-diseases-header-input-wrap">
            <input className="c-input-text-responsive js-input-diseases" value={this.state.word} name="" id="" type="text" placeholder="病名・診療科名で絞り込む" onChange={this.inputWord} />
          </div>
        </div>
        <div className="p-hospitals-search__modal-contents-diseases">
          {/* <div className="p-hospitals-search__modal-speciality-contents">
            <p className="p-hospitals-search__modal-contents-title">専門的な医療機関をお探しの方へ</p>
            <ul className="p-hospitals-search__modal-contents-list js-search-ka" style={{ display: this.state.isActiveKa ? 'flex' : 'none' }}>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link js-speciality-input" onClick={this.jsSpecialityInput}>体軸性脊椎関節炎<span>たいじくせいせきついかんせつえん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">家族性地中海熱<span>かぞくせいちちゅうかいねつ</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">アボット血糖値測定<span>アボットけっとうちそく</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">間質性肺炎<span>かんしつせいはいえん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">過活動膀胱(排尿)<span>かかつどうぼうふ</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">メドトロニック脊椎（脊髄を圧迫する病気）<span>メドトロニックせきつい</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">糖尿病<span>とうにょうびょう</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">漢方<span>かんぽう</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">乾癬<span>かんせん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">補聴器<span>ほちょうき</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">鉄欠乏性貧血<span>てつけつぼうせいひんけつ</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">重症筋無力症<span>じゅうしょうきんむりょくしょう</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">炎症性腸疾患（クローン病・潰瘍性大腸炎）<span>えんしょうせいちょうしっかん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">糖尿病黄斑浮腫<span>とうにょうびょうおうはんふしゅ</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">睡眠関連疾患<span>すいみんかんれんしっかん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">認知症<span>にんちしょう</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item js-search-ka" style={{ display: this.state.isActiveKa ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">過敏性腸症候群<span>かびんせいちょうしょうこうぐん</span></a></li>
              <li className="p-hospitals-search__modal-contents-list-item" style={{ display: this.state.isActiveDiseasesOther ? 'block' : 'none' }}><a href="" className="p-hospitals-search__modal-contents-list-link">DLBCL（CART＋移植対応）<span>ディーエルビーシーエル（シーエーアールティー＋いしょくたいおう）</span></a></li>
            </ul>
            <p className="p-hospitals-search__modal-contents-result0" style={{ display: this.state.isActiveResult0 ? 'block' : 'none' }}>検索結果はありません</p>
          </div> */}
          {/* 病名 */}
          <div className="p-hospitals-search__modal-diseases-contents">
            <p className="p-hospitals-search__modal-contents-title">病名</p>
            { this.canDisplaySuggestDiseases() ? this.renderSearching() : this.renderSuggestDiseases()}
          </div>
          {/* 診療科目 */}
          <div className="p-hospitals-search__modal-diseases-contents">
            <p className="p-hospitals-search__modal-contents-title--subject">診療科目</p>
            {Object.entries(departmentData).map((department, idx) => {
              const [name, items] = department;
              return (
                <ModalItem key={idx} subTitle={name} itemList={Object.entries(items)} isActiveKa={this.state.isActiveKa} handleSelectDepartment={this.props.handleSelectDepartment} />
              );
            })}
            {Object.keys(departmentData).length === 0 &&
              this.renderSuggestResult0()
            }
          </div>
        </div>
      </div>
    );
  }
}

ModalDiseases.propTypes = {
  departmentSection: PropTypes.object.isRequired
};

export default ModalDiseases;
