import { AbstractComponent } from '../../common/atoms/index';

export default class RecentHospitalComponent extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      recentHospitals: [],
      viewMoreRecentHospital: false
    };

    this.fetchRecentHospitals = this.fetchRecentHospitals.bind(this);
  }

  fetchRecentHospitals () {
    const apiLocation = `${this.props.siliconHost}/recent_hospitals`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState(
          { recentHospitals: data.hospitals }
        );
      });
  }
}
