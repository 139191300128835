import React from 'react';
import PropTypes from 'prop-types';

// 病名/診療科/症状/治療名のメニューリスト
export default function TreatmentModalMenuList (props) {
  return (
    <ul className="c-mn-sel-list-group">
      {/*
      <li className="c-mn-sel-list">
        <a onClick={() => { props.setModal('diseaseAlphabeticalRowSelect'); }}>
          <div className="c-mn-sel-txt">
            <p className="c-mn-sel-title">病名を選択する</p>
          </div>
          <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
            <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
          </svg>
        </a>
      </li>
      */}
      <li className="c-mn-sel-list">
        <a onClick={() => { props.setModal('departmentDivisionSelect'); }}>
          <div className="c-mn-sel-txt">
            <p className="c-mn-sel-title">診療科/専門外来を選択する</p>
          </div>
          <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36">
            <path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path>
          </svg>
        </a>
      </li>
    </ul>
  );
}

TreatmentModalMenuList.propTypes = {
  setModal: PropTypes.func
};
