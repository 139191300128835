import React from 'react';
import SearchCondition from '../../../../common/atoms/hospitals/SearchCondition';
import {
  AbstractComponent
} from '../../../../common/atoms/index';
import HospitalSearchMessage from '../../../../common/atoms/HospitalSearchMessage';

export default class BaseForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      values: {
        treatment: '',
        area: ''
      },
      condition: {
        treatments: [], // 複数選択可
        area: null, // 一つのみ選択可
        options: [] // 複数選択可
      },
      suggest: {
        treatments: {},
        areas: {}
      },
      showSuggest: {
        treatments: false,
        areas: false
      },
      showForm: false, // SearchForm用
      isModalOpen: false,
      queueWords: [] // 素早く検索されたときのためにキューで検索ワードを管理する
    };
    this.maxTreatmentsLength = 5;
    this.searchWaitingTime = 500; // 検索ボックス入力時に検索まで待つ時間（ミリ秒）
    this.handleChangeValueTreatment = this.handleChangeValueTreatment.bind(this);
    this.handleChangeValueArea = this.handleChangeValueArea.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    this.handleClearOptions = this.handleClearOptions.bind(this);
    this.findOption = this.findOption.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.setState(state => {
      state.condition.treatments = this.props.searchCondition.treatmentConditions;
      state.condition.area = this.props.searchCondition.areaCondition;
      state.condition.options = this.props.searchCondition.options;
      return { condition: state.condition };
    });
  }

  fetchSuggestTreatments (word) {
    const apiLocation = `${this.props.mixerHost}/suggest/treatments?word=${word}`;
    this
      .fetch(apiLocation)
      .then(data => {
        // 病名（疾患名称）での検索はできないので強制的に空にする。mixierで空を返すか迷ったがsiliconだけで完結させる
        data.data.diseases = [];

        this.setState(state => {
          state.suggest.treatments = data.data;
          state.showSuggest = { treatments: Object.values(data.data).some(data => data.length > 0), areas: false };
          return { suggest: state.suggest, showSuggest: state.showSuggest };
        });
      });
  }

  fetchSuggestAreas (word) {
    const apiLocation = `${this.props.mixerHost}/suggest/areas?word=${word}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState(state => {
          const suggests = {
            prefectures: [],
            cities: [],
            stations: []
          };
          // 市区町村の表示名加工
          data.data.prefectures.forEach(prefecture => {
            suggests.prefectures.push({ type: 'prefecture', code: prefecture.prefecture_code, name: prefecture.prefecture_name });
          });
          // 市区町村の表示名加工
          data.data.cities.forEach(city => {
            suggests.cities.push({ type: 'city', code: city.city_code, name: `${city.city_name}(${city.prefecture_name})` });
          });
          // 駅の表示名加工
          data.data.stations.forEach(station => {
            suggests.stations.push({ type: 'station', code: station.station_code, name: `${station.station_name}駅(${station.line_name})` });
          });

          state.suggest.areas = suggests;
          state.showSuggest = { treatments: false, areas: Object.values(data.data).some(data => data.length > 0) };
          return { suggest: state.suggest, showSuggest: state.showSuggest };
        });
      });
  }

  handleOpenModal (event) {
    event.preventDefault();
    document.body.classList.add('fixed');
    this.setState({ isModalOpen: true });
  }

  handleCloseModal (event) {
    event.preventDefault();
    document.body.classList.remove('fixed');
    this.setState({ isModalOpen: false });
  }

  handleChangeValueTreatment (event) {
    const word = event.target.value;
    this.setState(state => {
      state.values.treatment = word;
      return { values: state.values };
    });

    if (word === '') {
      this.setState(state => {
        state.suggest = {
          treatments: {},
          areas: {}
        };
        state.showSuggest.treatments = false;
        return { suggest: state.suggest, showSuggest: state.showSuggest };
      });
      return;
    }

    // [NOTE] 処理の流れ
    // 1. state.queueWordsに検索ワードを詰める
    // 2. 0.5秒待つ
    // 3. state.queueWordsの最新の単語と現行の検索ワードを比較
    // ---- 一致すれば検索 & state.queueWordsをクリア
    // ---- 一致しなければ何もせず処理を終了
    // ex. "yama"で検索する場合、state.queueWordsは右記のようになる ["y", "ya", "yam", "yama"]
    this.setState(state => {
      state.queueWords.push(word);
      return { queueWords: state.queueWords };
    });
    setTimeout(function () {
      const latestQueueWord = this.state.queueWords.slice(-1)[0];
      if (latestQueueWord === word) {
        this.fetchSuggestTreatments(word);
        this.setState(state => {
          state.queueWords = [];
          return { queueWords: state.queueWords };
        });
      }
    }.bind(this), this.searchWaitingTime);
  }

  handleChangeValueArea (event) {
    const word = event.target.value;
    this.setState(state => {
      state.values.area = word;
      return { values: state.values };
    });
    if (word === '') {
      this.setState(state => {
        state.suggest = {
          treatments: {},
          areas: {}
        };
        state.showSuggest.areas = false;
        return { suggest: state.suggest, showSuggest: state.showSuggest };
      });
      return;
    }

    // [NOTE] 処理の流れ
    // 1. state.queueWordsに検索ワードを詰める
    // 2. 0.5秒待つ
    // 3. state.queueWordsの最新の単語と現行の検索ワードを比較
    // ---- 一致すれば検索 & state.queueWordsをクリア
    // ---- 一致しなければ何もせず処理を終了
    // ex. "yama"で検索する場合、state.queueWordsは右記のようになる ["y", "ya", "yam", "yama"]
    this.setState(state => {
      state.queueWords.push(word);
      return { queueWords: state.queueWords };
    });
    setTimeout(function () {
      const latestQueueWord = this.state.queueWords.slice(-1)[0];
      if (latestQueueWord === word) {
        this.fetchSuggestAreas(word);
        this.setState(state => {
          state.queueWords = [];
          return { queueWords: state.queueWords };
        });
      }
    }.bind(this), this.searchWaitingTime);
  }

  handleChangeOption (event) {
    const name = event.target.name;
    const checked = event.target.checked;
    this.setState(state => {
      if (checked && state.condition.options.every(option => option.type !== name)) {
        state.condition.options.push({ type: name, code: checked });
      } else {
        const index = state.condition.options.findIndex(option => option.type === name);
        state.condition.options.splice(index, 1);
      }
      return { condition: state.condition };
    });
  }

  handleRemoveOption (event) {
    event.preventDefault();
    this.setState(state => {
      const index = state.condition.options.findIndex(option => option.type === event.target.name);
      state.condition.options.splice(index, 1);
      return { condition: state.condition };
    });
  }

  handleClearOptions (event) {
    event.preventDefault();
    this.setState(state => {
      state.condition.options = [];
      return { condition: state.condition };
    });
  }

  findOption (name) {
    return this.state.condition.options.some(option => option.type === name);
  }

  handleSelectSuggestTreatment (treatment) {
    if (this.state.condition.treatments.length >= this.maxTreatmentsLength) {
      alert(HospitalSearchMessage.TreatmentsMaxCountAlert);
      this.setState(state => {
        state.values.treatment = '';
        state.showSuggest.treatments = false;
        return { showSuggest: state.showSuggest };
      });
      return;
    }
    if (this.state.condition.treatments.some(condition => condition.code === treatment.code)) {
      this.setState(state => {
        state.values.treatment = '';
        state.showSuggest.treatments = false;
        return { values: state.values, showSuggest: state.showSuggest };
      });
      return;
    }

    this.setState(state => {
      state.values.treatment = '';
      state.condition.treatments.push(treatment);
      state.showSuggest.treatments = false;
      return { values: state.values, condition: state.condition, showSuggest: state.showSuggest };
    });
  }

  handleSelectSuggestArea (area) {
    this.setState(state => {
      state.values.area = '';
      state.condition.area = area;
      state.showSuggest.areas = false;
      return { values: state.values, condition: state.condition, showSuggest: state.showSuggest };
    });
  }

  handleRemoveSuggestTreatment (event, treatment) {
    event.preventDefault();
    this.setState(state => {
      const index = state.condition.treatments.findIndex(condition => condition.code === treatment.code);
      state.condition.treatments.splice(index, 1);
      return { condition: state.condition };
    });
  }

  handleRemoveSuggestArea (event) {
    event.preventDefault();
    this.setState(state => {
      state.condition.area = null;
      return { condition: state.condition };
    });
  }

  handleSubmit (event) {
    event.preventDefault();
    const searchCondition = new SearchCondition(this.state.condition);
    location.href = searchCondition.url();
  }

  renderSuggestTreatments () {
    const suggests = {
      treatments: [
        { key: 'diseases', label: '病名' },
        { key: 'checkups', label: '検査名' },
        { key: 'treatments', label: '治療名' },
        { key: 'departments', label: '診療科' }
      ]
    };

    return (
      <div className="p-institution-search__search-form-fixed-type-suggest js-suggest--fixed" style={{ display: 'block' }}>
        {suggests.treatments.map(suggest => {
          const treatments = this.state.suggest.treatments[suggest.key];
          if (!treatments || treatments.length === 0) {
            return null;
          }

          return (
            <div key={suggest.key} className="p-institution-search__search-form-fixed-type-suggest-inner">
              <p className="p-institution-search__search-form-fixed-type-suggest-inner-title">{suggest.label}</p>
              <ul className="c-mn-tag-list">
                {treatments.map(treatment => {
                  return (
                    <li key={`${treatment.name}${treatment.code}`}>
                      <a
                        className="c-mn-tag-link"
                        href=""
                        onClick={event => {
                          event.preventDefault();
                          this.handleSelectSuggestTreatment(treatment);
                        }}
                      >
                        {treatment.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  renderSuggestAreas () {
    const suggests = {
      areas: [
        { key: 'prefectures', label: '都道府県' },
        { key: 'cities', label: '市区町村' },
        { key: 'stations', label: '駅' }
      ]
    };

    return (
      <div className="p-institution-search__search-form-fixed-type-suggest js-suggest--fixed" style={{ display: 'block' }}>
        {suggests.areas.map(suggest => {
          const areas = this.state.suggest.areas[suggest.key];
          if (!areas || areas.length === 0) {
            return null;
          }

          return (
            <div key={suggest.key} className="p-institution-search__search-form-fixed-type-suggest-inner">
              <p className="p-institution-search__search-form-fixed-type-suggest-inner-title">{suggest.label}</p>
              <ul className="c-mn-tag-list">
                {areas.map(area => {
                  return (
                    <li key={`${area.name}${area.code}`}>
                      <a
                        className="c-mn-tag-link"
                        href=""
                        onClick={event => {
                          event.preventDefault();
                          this.handleSelectSuggestArea(area);
                        }}
                      >
                        {area.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  renderPrevSearchConditionUrl () {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');

    for (const cookie of cookiesArray) {
      const cookieArray = cookie.split('=');
      if (cookieArray[0].match(/search_condition_query/)) {
        const text = '/hospitals/search?' + decodeURIComponent(cookieArray[1]);
        return (
          text
        );
      }
    }
    return null;
  }

  renderPrevSearchConditionLabel () {
    const cookies = document.cookie;
    const cookiesArray = cookies.split(';');

    for (const cookie of cookiesArray) {
      const cookieArray = cookie.split('=');
      if (cookieArray[0].match(/search_condition_label/)) {
        return (
          decodeURIComponent(cookieArray[1])
        );
      }
    }
    return null;
  }
}
