import React from 'react';
import PropTypes from 'prop-types';

// 病名/診療科/症状/治療名の選択済みサジェストリスト
export default function TreatmentModalSelectedSuggestList (props) {
  return (
    <div className="p-institution-search__modal-search-tag">
      <ul className="c-mn-tag-list">
        {props.searchTreatmentCondition.map((treatmentCondition) => {
          return (
            <li key={`${treatmentCondition.type}-${treatmentCondition.code}`}>
              <div className="c-mn-tag">
                {treatmentCondition.name}
                <span onClick={() => props.handleDeleteSelected(treatmentCondition)}>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="6" fill="white"></circle><path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.14688 8.79825C3.88999 9.05514 3.4735 9.05514 3.21661 8.79825C2.95972 8.54136 2.95972 8.12486 3.21661 7.86798L5.07716 6.00742L3.24966 4.17992C2.99277 3.92303 2.99277 3.50653 3.24966 3.24964C3.50655 2.99276 3.92305 2.99276 4.17994 3.24964L6.00744 5.07715L7.86798 3.21661C8.12486 2.95972 8.54136 2.95972 8.79825 3.21661C9.05514 3.4735 9.05514 3.88999 8.79825 4.14688L6.93771 6.00742L8.8313 7.90101C9.08819 8.1579 9.08819 8.5744 8.8313 8.83129C8.57442 9.08817 8.15792 9.08817 7.90103 8.83129L6.00744 6.93769L4.14688 8.79825Z" fill="#A3E3E3"></path></svg>
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

TreatmentModalSelectedSuggestList.propTypes = {
  searchTreatmentCondition: PropTypes.array,
  handleDeleteSelected: PropTypes.func
};
