import React from 'react';
import IconSearch from '../../atoms/IconSearch';
import SearchCondition from '../../../../common/atoms/hospitals/SearchCondition';
import {
  AbstractComponent
} from '../../../../common/atoms/index';

export default class TopAreaForm extends AbstractComponent {
  constructor (props) {
    super(props);
    this.state = {
      value: '',
      suggest: {},
      showSuggest: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (event) {
    const word = event.target.value;
    this.setState({ value: word });
    this.fetchSuggests(word);
  }

  handleSelect (suggest) {
    const searchCondition = new SearchCondition({ area: suggest });
    location.href = searchCondition.url();
  }

  fetchSuggests (word) {
    if (word === '') {
      this.setState({ suggest: {}, showSuggest: false });
      return;
    }

    const apiLocation = `${this.props.mixerHost}/suggest/areas?word=${word}`;
    this
      .fetch(apiLocation)
      .then(data => {
        this.setState(state => {
          const suggests = {
            prefectures: [],
            cities: [],
            stations: []
          };
          // 都道府県の表示名加工
          data.data.prefectures.forEach(prefecture => {
            suggests.prefectures.push({ type: 'prefecture', code: prefecture.prefecture_code, name: prefecture.prefecture_name });
          });
          // 市区町村の表示名加工
          data.data.cities.forEach(city => {
            suggests.cities.push({ type: 'city', code: city.city_code, name: `${city.city_name}(${city.prefecture_name})` });
          });
          // 駅の表示名加工
          data.data.stations.forEach(station => {
            suggests.stations.push({ type: 'station', code: station.station_code, name: `${station.station_name}駅(${station.line_name})` });
          });

          state.suggest.areas = suggests;
          state.showSuggest = { treatments: false, areas: Object.values(data.data).some(data => data.length > 0) };
          return { suggest: state.suggest, showSuggest: state.showSuggest.areas };
        });
      });
  }

  renderSuggest () {
    const suggests = {
      areas: [
        { key: 'prefectures', label: '都道府県' },
        { key: 'cities', label: '市区町村' },
        { key: 'stations', label: '駅' }
      ]
    };

    return (
      <div className="p-institution-search__search-form-fixed-type-suggest js-suggest--fixed" style={{ display: 'block' }}>
        {suggests.areas.map(suggest => {
          const areas = this.state.suggest.areas[suggest.key];
          if (!areas || areas.length === 0) {
            return null;
          }

          return (
            <div key={suggest.key} className="p-institution-search__search-form-fixed-type-suggest-inner">
              <p className="p-institution-search__search-form-fixed-type-suggest-inner-title">{suggest.label}</p>
              <ul className="c-mn-tag-list">
                {areas.map(area => {
                  return (
                    <li key={`${area.name}${area.code}`}>
                      <a
                        className="c-mn-tag-link"
                        href=""
                        onClick={event => {
                          event.preventDefault();
                          this.handleSelect(area);
                        }}
                      >
                        {area.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  render () {
    return (
      <div className="p-institution-search__input">
        <label className="p-institution-search__input-label">エリア/駅</label>
        <input
          value={this.state.value}
          type="text"
          className="c-mn-input-text"
          placeholder="エリア/駅を入力"
          onChange={this.handleChange}
        />
        {this.state.showSuggest ? this.renderSuggest() : null}
        <button
          className="p-institution-search__input-btn"
          onClick={event => event.preventDefault()}
        >
          <IconSearch />
        </button>
      </div>
    );
  }
}
