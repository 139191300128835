import React from 'react';
import PropTypes from 'prop-types';
import SearchCondition from '../../../../common/atoms/hospitals/SearchCondition';
import Options from '../../../../common/atoms/hospitals/Options';
import {
  AreaNav,
  TypeNav
} from '../../molecules/hospitals/index';

export default class Aside extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      condition: {
        treatments: [],
        area: null,
        options: [],
        institutionNameOrDoctorName: props.searchCondition.institutionNameOrDoctorName
      }
    };
    this.maxTreatmentsLength = 5;
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.findOption = this.findOption.bind(this);
    this.searchUrl = this.searchUrl.bind(this);
    this.redirectToCurrentConditionUrl = this.redirectToCurrentConditionUrl.bind(this);
    this.canTreatmentsSearch = this.canTreatmentsSearch.bind(this);
    this.handleChangeInstitutionNameOrDoctorName = this.handleChangeInstitutionNameOrDoctorName.bind(this);
    this.handleClickInstitutionNameOrDoctorNameSearchButton = this.handleClickInstitutionNameOrDoctorNameSearchButton.bind(this);
  }

  componentDidMount () {
    this.setState(state => {
      state.condition.treatments = this.props.searchCondition.treatmentConditions;
      state.condition.area = this.props.searchCondition.areaCondition;
      state.condition.options = this.props.searchCondition.options;
      state.condition.institutionNameOrDoctorName = this.props.searchCondition.institutionNameOrDoctorName;
      return { condition: state.condition };
    });
  }

  handleChangeOption (event) {
    const name = event.target.name;
    const checked = event.target.checked;
    const dupCondition = JSON.parse(JSON.stringify(this.state.condition));
    if (checked && dupCondition.options.every(option => option.type !== name)) {
      dupCondition.options.push({ type: name, code: checked });
    } else {
      const index = dupCondition.options.findIndex(option => option.type === name);
      dupCondition.options.splice(index, 1);
    }
    this.redirectToCurrentConditionUrl(dupCondition);
  }

  redirectToCurrentConditionUrl (condition) {
    location.href = this.searchUrl({}, condition);
  }

  findOption (name) {
    return this.state.condition.options.some(option => option.type === name);
  }

  searchUrl (additionalCondition, existCondition = this.state.condition) {
    const condition = existCondition;
    if (additionalCondition.treatment && condition.treatments.length < this.maxTreatmentsLength) {
      condition.treatments.push(additionalCondition.treatment);
    }
    if (additionalCondition.area) {
      condition.area = additionalCondition.area;
    }

    const searchCondition = new SearchCondition(condition);
    return searchCondition.url();
  }

  canTreatmentsSearch () {
    return this.state.condition.treatments.length < this.maxTreatmentsLength;
  }

  handleChangeInstitutionNameOrDoctorName (event) {
    const institutionNameOrDoctorName = event.target.value;
    const condition = this.state.condition;
    condition.institutionNameOrDoctorName = institutionNameOrDoctorName;
    this.setState({ condition: condition });
  }

  handleClickInstitutionNameOrDoctorNameSearchButton () {
    location.href = this.searchUrl({});
  }

  render () {
    return (
      <aside>
        <form>
          <div className="p-institution-search__nav">
            <TypeNav mixerHost={this.props.mixerHost} searchUrl={this.searchUrl} params={this.props.params} condition={this.state.condition} canTreatmentsSearch={this.canTreatmentsSearch} />
            <AreaNav mixerHost={this.props.mixerHost} searchUrl={this.searchUrl} params={this.props.params} condition={this.state.condition} />
            {Options.all().map(group => {
              return (
                <React.Fragment key={group.id}>
                  <p className="p-institution-search__nav-title">{group.name}</p>
                  {group.items.map(item => {
                    return (
                      <div key={item.id} className="c-mn-checkbox-btn">
                        <input
                          className="c-mn-checkbox-btn__checkbox"
                          id={item.id}
                          name={item.name}
                          value={item.value}
                          type="checkbox"
                          checked={this.findOption(item.name)}
                          onChange={this.handleChangeOption}
                        />
                        <label className="c-mn-checkbox-btn__label" htmlFor="checkbox0101">
                          <svg width="29" height="20" viewBox="0 0 29 20" fill="none" className="c-mn-icon-check" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000" className="c-mn-icon-check-path"></path>
                            <path d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000" className="c-mn-icon-check-path"></path>
                          </svg>
                          {item.value}
                        </label>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}

            <p className="p-institution-search__nav-title">病院名から探す</p>
            <div className="p-institution-search__nav-input">
              <input
                className="c-mn-input-text"
                type="text"
                value={this.state.condition.institutionNameOrDoctorName}
                onChange={this.handleChangeInstitutionNameOrDoctorName}
                placeholder="病院名を入力"
              />
              <button
                type="button"
                className="p-institution-search__nav-input-btn"
                onClick={this.handleClickInstitutionNameOrDoctorNameSearchButton}
              >
                <span>検索</span>
              </button>
            </div>
          </div>
        </form>
      </aside>
    );
  }
}

Aside.propTypes = {
  mixerHost: PropTypes.string,
  params: PropTypes.object,
  searchCondition: PropTypes.object
};
