import React from 'react';
import FetchPublicApiComponent from '../../../../../common/atoms/FetchPublicApiComponent';

export default class ModalDetail extends FetchPublicApiComponent {
  closeModal () {
    this.props.setModal(null);
    this.props.setPreviousModal(null);
  }

  handleClickTreatmentModal () {
    this.props.setModal('treatment');
    this.props.setPreviousModal('detail');
  }

  handleClickAreaModal () {
    this.props.setModal('area');
    this.props.setPreviousModal('detail');
  }

  handleClickSearch () {
    location.href = this.props.searchUrl();
  }

  // その他の検索条件(チェックボックス)の操作
  handleChangeOptionCondition (event, optionConditionName) {
    const checked = event.target.checked;
    if (checked) {
      this.props.setSearchOptionCondition({ type: optionConditionName, code: checked });
    } else {
      this.props.deleteSearchOptionCondition(optionConditionName);
    }
  }

  handleChangeInstitutionNameOrDoctorName (event) {
    const institutionNameOrDoctorName = event.target.value;
    this.props.setInstitutionNameOrDoctorNameCondition(institutionNameOrDoctorName);
  }

  render () {
    return (
      <div className={this.props.isActive ? 'p-institution-search__modal-detail js-modal-detail is-active' : 'p-institution-search__modal-detail js-modal-detail'}>
        <div className="p-institution-search__modal-header--detail">
          <a className="p-institution-search__modal-header-clear js-modal-clear" onClick={this.props.clearSearchCondition}>全条件削除</a>
          <p className="p-institution-search__modal-header-title">検索条件</p>
          <a className="p-institution-search__modal-header-close" onClick={() => { this.closeModal(); }}>
            <svg width="15" viewBox="0 0 21 20" fill="none" className="c-mn-icon-close" xmlns="http://www.w3.org/2000/svg"><path d="M1.42197 0.492279C0.765595 1.14865 0.765595 2.21284 1.42197 2.86921L8.55276 10L1.42197 17.1308C0.765595 17.7872 0.765595 18.8514 1.42197 19.5077C2.07834 20.1641 3.14252 20.1641 3.7989 19.5077L10.9297 12.3769L18.0605 19.5077C18.7168 20.1641 19.781 20.1641 20.4374 19.5077C21.0938 18.8513 21.0938 17.7872 20.4374 17.1308L13.3066 10L20.4374 2.86921C21.0938 2.21284 21.0938 1.14865 20.4374 0.492278C19.781 -0.164093 18.7168 -0.164092 18.0605 0.492278L10.9297 7.62307L3.7989 0.492279C3.14252 -0.164092 2.07834 -0.164093 1.42197 0.492279Z" fill="#000" className="c-mn-icon-close-path"/></svg>
            閉じる
          </a>
          <hr className="c-mn-line-second"/>
        </div>
        <div className="p-institution-search__modal-contents">
          <div className="p-institution-search__modal-input">
            <div className="pg-institution-search-result__search-area-input">
              <div className="pg-institution-search-result__search-area-input-diseases">
                {/* <label className="pg-institution-search-result__search-area-input-label">病名/診療科/症状/治療名</label> */}
                <label className="pg-institution-search-result__search-area-input-label">診療科/症状/治療名</label>
                {/* @NOTE: onChangeは実装上必要ないがreactのwarningが出るため何もしない空関数を指定している */}
                <input
                  className="c-mn-input-text" type="text" placeholder="指定する"
                  value={ this.props.searchTreatmentCondition ? this.props.displaySearchTreatmentConditions() : '' }
                  onClick={() => this.handleClickTreatmentModal()}
                  onChange={() => {}}
                />
              </div>
              <div className="pg-institution-search-result__search-area-input-area">
                <label className="pg-institution-search-result__search-area-input-label">エリア/駅</label>
                {/* @NOTE: onChangeは実装上必要ないがreactのwarningが出るため何もしない空関数を指定している */}
                <input
                  className="c-mn-input-text" type="text" placeholder="指定する"
                  value={ this.props.searchAreaCondition ? this.props.searchAreaCondition.name : '' }
                  onClick={() => this.handleClickAreaModal()}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="c-mn-checkbox-btn c-mn-checkbox-btn--no-border">
              <input
                className="c-mn-checkbox-btn__checkbox"
                id="checkbox01"
                name="checkbox_cul2"
                type="checkbox"
                onChange={event => this.handleChangeOptionCondition(event, 'is_free_mail_consultation_available')}
                checked={this.props.findSearchOptionCondition('is_free_mail_consultation_available')}
              />
              <label className="c-mn-checkbox-btn__label" htmlFor="checkbox01">
                <svg className="c-mn-icon-check" width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path className="c-mn-icon-check-path" d="M1.51592 10.4222C0.75511 9.66136 0.755111 8.42785 1.51592 7.66704C2.27673 6.90623 3.51024 6.90623 4.27105 7.66704L11.7129 15.1089C12.4737 15.8697 12.4737 17.1032 11.7129 17.864C10.9521 18.6248 9.71855 18.6248 8.95774 17.864L1.51592 10.4222Z" fill="#000"></path><path className="c-mn-icon-check-path" d="M11.9062 18.0573C11.1454 18.8181 9.91185 18.8181 9.15104 18.0573C8.39023 17.2965 8.39023 16.063 9.15104 15.3022L23.4527 1.00054C24.2135 0.23973 25.447 0.239729 26.2078 1.00054C26.9686 1.76135 26.9686 2.99486 26.2078 3.75567L11.9062 18.0573Z" fill="#000"></path></svg>
                医師に無料メール相談ができる病院
              </label>
            </div>
          </div>
          <hr className="c-mn-line"/>
          <div className="p-institution-search__modal-input-btm">
            <p className="p-institution-search__modal-checkbox-title">病院名から探す</p>
            <input
              type="text"
              value={ this.props.institutionNameOrDoctorNameCondition ? this.props.institutionNameOrDoctorNameCondition : '' }
              className="c-mn-input-text p-institution-search__modal-input-btm-hospital-name-input-text"
              onChange={event => this.handleChangeInstitutionNameOrDoctorName(event)}
              placeholder="病院名を入力"/>
          </div>
        </div>
        <div className="p-institution-search__modal-btn">
          <a
            className="c-mn-btn--first-s"
            onClick={event => {
              event.preventDefault();
              this.handleClickSearch();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.95 21.14" width="15" className="c-mn-icon-search"><path d="M20.08,21.54a1.26,1.26,0,0,1-.89-.37l-3.93-3.93-.27.19a9.21,9.21,0,0,1-5.26,1.63,9.33,9.33,0,1,1,9.33-9.33,9.25,9.25,0,0,1-1.78,5.48l-.2.27,3.9,3.9a1.27,1.27,0,0,1-.9,2.16ZM9.73,2.45A7.28,7.28,0,1,0,17,9.73,7.29,7.29,0,0,0,9.73,2.45Z" transform="translate(-0.4 -0.4)" className="c-mn-icon-search-path"></path></svg>
            <span>この条件で検索する</span>
          </a>
        </div>
      </div>
    );
  }
}
