export default class JapaneseAlphabeticalOrders {
  static JapaneseAlphabeticalOrders = {
    '01': [
      { code: '01', name: 'あ' },
      { code: '02', name: 'い' },
      { code: '03', name: 'う' },
      { code: '04', name: 'え' },
      { code: '05', name: 'お' }
    ],
    '02': [
      { code: '06', name: 'か' },
      { code: '07', name: 'き' },
      { code: '08', name: 'く' },
      { code: '09', name: 'け' },
      { code: '10', name: 'こ' }
    ],
    '03': [
      { code: '11', name: 'さ' },
      { code: '12', name: 'し' },
      { code: '13', name: 'す' },
      { code: '14', name: 'せ' },
      { code: '15', name: 'そ' }
    ],
    '04': [
      { code: '16', name: 'た' },
      { code: '17', name: 'ち' },
      { code: '18', name: 'つ' },
      { code: '19', name: 'て' },
      { code: '20', name: 'と' }
    ],
    '05': [
      { code: '21', name: 'な' },
      { code: '22', name: 'に' },
      { code: '23', name: 'ぬ' },
      { code: '24', name: 'ね' },
      { code: '25', name: 'の' }
    ],
    '06': [
      { code: '26', name: 'は' },
      { code: '27', name: 'ひ' },
      { code: '28', name: 'ふ' },
      { code: '29', name: 'へ' },
      { code: '30', name: 'ほ' }
    ],
    '07': [
      { code: '31', name: 'ま' },
      { code: '32', name: 'み' },
      { code: '33', name: 'む' },
      { code: '34', name: 'め' },
      { code: '35', name: 'も' }
    ],
    '08': [
      { code: '36', name: 'や' },
      { code: '38', name: 'ゆ' },
      { code: '40', name: 'よ' }
    ],
    '09': [
      { code: '41', name: 'ら' },
      { code: '42', name: 'り' },
      { code: '43', name: 'る' },
      { code: '44', name: 'れ' },
      { code: '45', name: 'ろ' }
    ],
    10: [
      { code: '46', name: 'わ' }
    ]
  };

  static findByCode (code) {
    return this.JapaneseAlphabeticalOrders[code];
  }
}
