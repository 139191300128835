import React from 'react';
import PropTypes from 'prop-types';
import CommonSearch from '../../atoms/CommonSearch';
import SearchTopAreaModals from '../../organisms/hospitals/modal/SearchTopAreaModals';

export default class AreaSearchForm extends CommonSearch {
  render () {
    return (
      <>
        <div className="p-institution-search__block">
          <h3 className="c-mn-title-3">エリア/駅から探す</h3>
          <a className="p-institution-search__input js-modal-btn-area" onClick={() => this.setModal('area')}>
            <label className="p-institution-search__input-label">エリア/駅</label>
            <input className="c-mn-input-text" type="text" placeholder="エリア/駅を入力"/>
            <button className="p-institution-search__input-btn">
              <svg className="c-mn-icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.95 21.14" width="15"><path className="c-mn-icon-search-path" d="M20.08,21.54a1.26,1.26,0,0,1-.89-.37l-3.93-3.93-.27.19a9.21,9.21,0,0,1-5.26,1.63,9.33,9.33,0,1,1,9.33-9.33,9.25,9.25,0,0,1-1.78,5.48l-.2.27,3.9,3.9a1.27,1.27,0,0,1-.9,2.16ZM9.73,2.45A7.28,7.28,0,1,0,17,9.73,7.29,7.29,0,0,0,9.73,2.45Z" transform="translate(-0.4 -0.4)"></path></svg>
            </button>
          </a>
          <ul className="pg-institution-search-top__search-btn-list">
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/kanto">関東</a></li>
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/tohoku">北海道・東北</a></li>
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/hokurikuchubu">北陸・中部</a></li>
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/kinki">近畿</a></li>
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/chugokushikoku">中国・四国</a></li>
            <li className="pg-institution-search-top__search-btn-list-item"><a className="pg-institution-search-top__search-btn" href="/hospitals/kyushu">九州・沖縄</a></li>
          </ul>
          <hr className="c-mn-line"/>
        </div>
        {/* エリア/駅モーダルのDOM */}
        <SearchTopAreaModals
          modal={this.state.modal}
          previousModal={'areaSearchForm'}
          setModal={(modalName) => this.setModal(modalName)}
          setPreviousModal={(modalName) => this.setPreviousModal(modalName)}
          setSearchAreaCondition={(searchAreaObject) => this.setSearchAreaCondition(searchAreaObject)}
          searchAreaCondition={this.state.searchAreaCondition}
          clearSearchCondition={() => this.clearSearchCondition}
          mixerHost={this.props.mixerHost}
          searchUrl={this.searchUrl}
          searchSelectConditionUrl={(key, value) => this.searchSelectConditionUrl(key, value)}
        />
      </>
    );
  }
}

AreaSearchForm.propTypes = {
  mixerHost: PropTypes.string.isRequired
};

AreaSearchForm.defaultProps = {
  mixerHost: ''
};
