import React from 'react';
import PropTypes from 'prop-types';
import CommonSearch from '../../atoms/CommonSearch';
import SearchTopDiseaseModals from '../../organisms/hospitals/modal/SearchTopDiseaseModals';

export default class DiseaseSearchForm extends CommonSearch {
  render () {
    return (
      <>
        <div className="p-institution-search__block">
          <h3 className="c-mn-title-3">病名から探す</h3>
          <ul className="pg-institution-search-top__search-btn-list">
            <li className="pg-institution-search-top__search-btn-list-item">
              <a className="pg-institution-search-top__search-btn" onClick={() => this.setModal('diseaseAlphabeticalRowSelect')}>
                あいうえお順から
              </a>
            </li>
          </ul>
          <h4 className="c-mn-title-4">よく検索されている病名</h4>
          <ul className="c-mn-sel-list-group">
            <li className="c-mn-sel-list">
              <a href="">
                <div className="c-mn-sel-txt">
                  <p className="c-mn-sel-title">よく検索されている病名1</p>
                </div>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
              </a>
            </li>
            <li className="c-mn-sel-list">
              <a href="">
                <div className="c-mn-sel-txt">
                  <p className="c-mn-sel-title">よく検索されている病名2</p>
                </div>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
              </a>
            </li>
            <li className="c-mn-sel-list">
              <a href="">
                <div className="c-mn-sel-txt">
                  <p className="c-mn-sel-title">よく検索されている病名3</p>
                </div>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
              </a>
            </li>
            <li className="c-mn-sel-list">
              <a href="">
                <div className="c-mn-sel-txt">
                  <p className="c-mn-sel-title">よく検索されている病名4</p>
                </div>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
              </a>
            </li>
            <li className="c-mn-sel-list">
              <a href="">
                <div className="c-mn-sel-txt">
                  <p className="c-mn-sel-title">よく検索されている病名5</p>
                </div>
                <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>
              </a>
            </li>
          </ul>
          <a className="c-mn-btn--accordion-s" href="">
            <span>
              <svg className="c-mn-icon-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36"><path className="c-mn-icon-arrow-path" d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z"></path></svg>もっと見る
            </span>
          </a>
          <hr className="c-mn-line"/>
        </div>
        {/* 病名 モーダルのDOM */}
        <SearchTopDiseaseModals
          modal={this.state.modal}
          previousModal={'top'}
          setModal={(modalName) => this.setModal(modalName)}
          setPreviousModal={(modalName) => this.setPreviousModal(modalName)}
          setSearchTreatmentCondition={(searchAreaObject) => this.setSearchTreatmentCondition(searchAreaObject)}
          searchTreatmentCondition={this.state.searchTreatmentCondition}
          searchSelectConditionUrl={(key, value) => this.searchSelectConditionUrl(key, value)}
          clearSearchCondition={() => this.clearSearchCondition}
          mixerHost={this.props.mixerHost}
        />
      </>
    );
  }
}

DiseaseSearchForm.propTypes = {
  mixerHost: PropTypes.string.isRequired
};

DiseaseSearchForm.defaultProps = {
  mixerHost: ''
};
